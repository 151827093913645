import React from "react";
import { Backdrop, Divider, Menu } from "@mui/material";
import { useDispatch } from "react-redux";
import { IMAGES } from "../../../../constants/assets";
import { handleLogout } from "../../../../redux/actions/auth";
import styles from "./styles.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../../../router/routes";
import {
  DELETE_MODAL_CONFIRMATION,
  IMAGE_BASE_URL,
} from "../../../../constants/contants";
import { hasPermission, ristrictAccess } from "utils/helper";
import { useEffect, useState } from "react";
import { dataSetHasPermission, tabHandler } from "utils/helper";
import { PERMISSIONS, SUB_MODULES, MODULES } from "constants/accessControl";
import { getState } from "redux/store";
import ConfirmationModal from "components/modal/confirmationModal";
import { APIS } from "api";
import ToastMessage from "components/layout/toast";
import { TOAST_MESSAGE } from "constants/toastMessage";
import { addEditApi, listApi } from "api/commonApis";
import { toast } from "react-toastify";

const RISTRICT_PROFILE_MENU = [{ title: "Profile", tab: "profiledetails" }];

const ProfileMenu = ({ open, anchorEl, handleClose, profileData }) => {
  ///// PRINT ALL THE MENU IN PROFILE MENU/////

  const PROFILE_MENU = [
    { title: "Profile", tab: "profiledetails", hasAccess: true },
    { title: "Calendar" },
    { title: "Change Password", tab: "changepassword", hasAccess: true },
    { title: "Assignments", tab: "assignments", hasAccess: true },
    {
      title: "Users",
      tab: "tl-admins",
      hasAccess: hasPermission(
        MODULES.DATA_SETS,
        SUB_MODULES.USER_MANAGMENT,
        PERMISSIONS.READ
      ),
    },
    {
      title: "Data Sets",
      tab: tabHandler(),
      hasAccess: dataSetHasPermission(),
    },
    //{ title: "App Shortcuts", tab: "app-shortcuts", hasAccess: true },
  ];

  ////// STATES//////

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [menu, setMenu] = useState();
  const [hide_manage, set_hide_manage] = useState(true);
  const [user, set_user] = useState();

  //deletion

  const { id } = useParams();
  const [_open, _setOpen] = React.useState(false);
  const _handleOpen = () => _setOpen(true);
  const _handleClose = () => _setOpen(false);
  const [talent, setTalent] = useState();

  const logout = () => {
    localStorage.setItem("local", "local");
    sessionStorage.setItem("session", "session");

    dispatch(handleLogout());
    localStorage.clear();
    sessionStorage.clear();
    const clearCacheData = () => {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      alert("Complete Cache Cleared");
    };
  };

  useEffect(() => {
    const user = getState()?.auth?.data;
    set_hide_manage(!user.selfSignUp);
    set_user(user);

    if (ristrictAccess()) {
      setMenu(RISTRICT_PROFILE_MENU);
    } else {
      setMenu(PROFILE_MENU);
    }
  }, []);

  useEffect(() => {
    const user = getState()?.auth?.data;
    if (user?.talentLabRole === "Talent") {
      listApi(APIS.LIST_TALENT_OVERVIEW, { talentId: id }).then((result) => {
        setTalent(result?.data[0]);
      });
    }
  }, []);

  const handlerDeleteCandidate = () => {
    if (ristrictAccess()) {
      addEditApi(APIS.REQUEST_DELETION, { type: 1 }).then((result) => {
        toast.success(
          <ToastMessage
            title={"Success"}
            message={TOAST_MESSAGE.DELETE_REQUEST}
          />
        );
        _handleClose();
      });
      _handleClose();
    }
  };

  ////// RETURN////////

  return (
    <Backdrop
      sx={{
        backgroundColor: "#ffffff94",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
      onClick={handleClose}
    >
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            background: "#f9f9f9",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "#f9f9f9",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <div className={styles.header}>
          {profileData?.imageUrl?.original ? (
            <img
              src={IMAGE_BASE_URL + profileData?.imageUrl?.original}
              className={styles.userImg}
              alt="Admin Image"
            />
          ) : (
            <h5 className={styles.userIntial}>
              {`${profileData?.name?.[0]} ${profileData?.lastName?.[0]}`}
            </h5>
          )}

          <div className={styles.userDetails}>
            <h3
              className={styles.userName}
            >{`${profileData?.name} ${profileData?.lastName}`}</h3>
            <span className={styles.jobRole}>{profileData?.roleName}</span>
          </div>
        </div>

        {hide_manage && (
          <Divider sx={{ bgcolor: "#dddddd", marginBottom: "10px" }} />
        )}

        {hide_manage && (
          <div className={styles.menu}>
            <h6 className={styles.menuTitle}>Manage</h6>

            {menu?.map(
              (item) =>
                item?.hasAccess && (
                  <span
                    className={styles.menuItem}
                    onClick={() => {
                      item.tab
                        ? navigate(`${ROUTES.USER_PROFILE}?tab=${item.tab}`)
                        : window.open(
                            "https://calendar.google.com/calendar/",
                            "_blank"
                          );
                    }}
                  >
                    {item.title}
                  </span>
                )
            )}
          </div>
        )}

        <Divider
          sx={{ bgcolor: "#dddddd", marginBottom: "10px", marginTop: "10px" }}
        />

        <div className={styles.menu}>
          {user?.selfSignUp && user?.talentLabRole === "Organizations" && (
            <span
              onClick={() => {
                navigate(
                  `/${
                    user?.talentLabRole === "Talent" ? "talent" : "clients"
                  }/overview/${user.commonId}?tab=profile`
                );
              }}
              className={styles.anchorMenuItem}
            >
              Profile
            </span>
          )}

          {user?.selfSignUp &&
            (user?.talentLabRole === "Talent" ||
              user?.talentLabRole === "Organizations") && (
              <span
                onClick={() => {
                  navigate(
                    `/${
                      user?.talentLabRole === "Talent" ? "talent" : "clients"
                    }/overview/${user.commonId}?tab=change-password`
                  );
                }}
                className={styles.anchorMenuItem}
              >
                Change Password
              </span>
            )}

          {user?.selfSignUp && user?.talentLabRole === "Talent" && (
            <span onClick={_handleOpen} className={styles.anchorMenuItem}>
              {" "}
              Request for Deletion
            </span>
          )}
          <a
            href="https://documentation.peopleasset.in"
            target="_blank"
            rel="noreferrer"
            className={styles.anchorMenuItem}
          >
            {/* <span className={styles.menuItem}> */}
            Help
            {/* </span> */}
          </a>
          <a
            href="mailto:connect@talentlab.tech?subject=TalentLab concern/feedback"
            target="_blank"
            rel="noreferrer"
            className={styles.anchorMenuItem}
          >
            {/* <span className={styles.menuItem}> */}
            Support
            {/* </span> */}
          </a>

          {user?.selfSignUp &&
            (user?.talentLabRole === "Talent" ||
              user?.talentLabRole === "Organizations") && (
              <span
                onClick={() => {
                  navigate(
                    `/${
                      user?.talentLabRole === "Talent" ? "talent" : "clients"
                    }/overview/${user.commonId}?tab=tl-shortcuts`
                  );
                }}
                className={styles.anchorMenuItem}
              >
                Download Shortcuts
              </span>
            )}

          {user?.talentLabRole != "Talent" &&
            user?.talentLabRole != "Organizations" && (
              <span
                className={styles.menuItem}
                onClick={() => {
                  navigate(`${ROUTES.USER_PROFILE}?tab=tl-shortcuts`);
                }}
              >
                Download Shortcuts
              </span>
            )}

          <span className={styles.menuItem} onClick={logout}>
            <img
              src={IMAGES.USER_LOGOUT}
              alt="logo"
              className={styles.menuIcon}
            />
            Sign Out
          </span>
        </div>
      </Menu>
      <ConfirmationModal
        title={DELETE_MODAL_CONFIRMATION}
        open={_open}
        reuqestDelete={ristrictAccess() ? true : false}
        closeModal={_handleClose}
        onChangeHanlder={handlerDeleteCandidate}
        data={`${talent?.firstName} ${talent?.middleName} ${talent?.lastName}`}
      />
    </Backdrop>
  );
};
export default ProfileMenu;
