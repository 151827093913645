import React from "react";
import styles from "./styles.module.scss";
import { getAssignmentCandidateStatus } from "utils/helper";
import moment from "moment";
import { assignmentTalentStatus } from "constants/contants";

const SummaryTable = ({ data }) => {
  return (
    <div className={styles.assignedtable}>
      <table class="table">
        <thead>
          <tr>
            <th scope="col" className={styles.thName}>
              Name
            </th>

            <th scope="col" className={styles.thStatus}>
              Current Status
            </th>
            <th scope="col" className={styles.thAssigned}>
              Assigned On
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.candidates?.map((data, index) => (
            <tr key={index}>
              <td style={{ verticalAlign: "middle" }}>
                <b>{`${data?.talentId?.firstName} ${data?.talentId?.lastName}  `}</b>
                <td
                  className={styles.designationStyles}
                >{`${data?.currentDesignation}`}</td>
              </td>
              <td>
                {/* <b>{getAssignmentCandidateStatus(data?.status)}</b> */}
                <b>
                  {
                    assignmentTalentStatus.filter((a) => {
                      return (a.value === 3 ? 2 : a.value) === data?.status;
                    })[0]?.label
                  }
                </b>
              </td>
              <td>{`${moment(data?.updatedAt).format(" DD MMMM YYYY")}`}</td>{" "}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default SummaryTable;
