export const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

export const statusCodes = {
  SUCCESS: 200,
};

export const APIS = {
  LOGIN: "/admin/login",
  LOGOUT: "/admin/logout",
  LIST_GET_ADMIN: "/admin/getAdmins",
  LIST_COMPENTENCY: "admin/listCompetencyDefaults",
  LIST_TALENT_OVERVIEW: "/admin/talentDetails",
  LIST_TALENTS: "/admin/talents",
  LIST_CLIENT: "/client/list",
  LIST_CLIENT_DETAILS: "client/listDetails",
  LIST_CLIENT_OVERVIEW: "client/overview",
  TALENT_DETAILS_TYPE_WISE: "/admin/talentDetailsTypeWise",
  ADD_EDIT_TALENT_PROFILE: "/admin/addEditTalentOverview",
  ADD_EDIT_TALENT_WORK: "/admin/addEditTalentWork",

  ADD_EDIT_TALENT_EDUCATION: "/admin/addEditTalentEducation",
  ADD_EDIT_TALENT_CERTIFICATION: "/admin/addEditTalentCertification",
  ADD_EDIT_TALENT_DOCUMENTS: "/admin/addEditTalentDocuments",
  //comp doc
  ADD_EDIT_TALENT_COMP_DOCUMENTS: "/admin/addEditTalentCompensationDocuments",
  //offer doc
  ADD_EDIT_TALENT_OFFER_DOCUMENTS: "/admin/addEditTalentOfferLetterDocuments",
  //resignation doc
  ADD_EDIT_TALENT_RESIGNATION_DOCUMENTS:
    "/admin/addEditTalentResignationDocuments",

  ADD_EDIT_TALENT_COMPENTENCY: "admin/addEditTalentCompetency",
  ADD_EDIT_TALENT_DOSSIER: "admin/createDossier",
  ADD_EDIT_CLIENT: "/client/addEditClient",
  ADD_EDIT_CLIENT_CONTRACTS: "/client/addEditContracts",
  ADD_EDIT_CLIENT_INVOICES: "/client/addEditInvoices",

  LIST_ASSIGNMENT: "/assignment/list",
  LIST_ASSIGNMENT_OVERVIEW: "/assignment/overview",
  LIST_ASSIGNED_CANDIDATES: "/assignment/listAssignedCandidates",
  ADD_EDIT_ASSIGNMNET: "/assignment/addEditAssignment",
  ADD_ASSIGNMENT_CANDIDATES: "/assignment/addCandidates",
  CHANGE_ARCHIVE_ASSIGNMENT: "/assignment/archiveAssignment",
  CHANGE_ASSIGNMENT_CANDIDATE_STATUS: "/assignment/changeCandidateStatus",

  ADMIN_DEFAULTS: "/admin/adminDefaults",

  LIST_COUNTRY_STATE_CITY: "api/getCountryStateCity",

  LIST_COMMENTS: "/comments/list",
  ADD_EDIT_COMMENTS: "/comments/addEditComment",
  ADD_EDIT_COMMENT_REPLY: "/comments/addEditReply",

  LIST_FILTER: "/admin/filtersList",

  ADMIN_PROFILE: "/admin/profile",
  ADMIN_UPDATE_PROFILE: "/admin/updateProfile",

  ADD_EDIT_FOLLOWUPS: "/admin/addEditFollowups",

  DELETE_DOCUMENT_DATA: "/api/deleteDocumentData",

  LIST_ACTIVITY_LOG: "/assignment/listActivityLog",

  LIST_DASHBOARD: "/admin/dashboard",

  LIST_DATASET: "/admin/dataSetsList",

  UPLOAD_IMAGE: "/api/uploadImage",

  ADD_EDIT_DATASET: "/admin/addEditDataSet",

  BLOCK_DATA: "/admin/blockData",

  LIST_ADMIN: "/admin/listAdmins",

  ADD_EDIT_ADMINS: "/admin/addEditAdmins",

  LIST_NOTIFICATIONS: "/admin/notifications",

  NOTIFICATION_ACTIONS: "/admin/notificationActions",

  GOOGLE_DETAILS: "admin/updateGoogleDetails",

  CLONE_ASSIGNMENT: "/assignment/cloneAssignment",

  FORGOT_PASSWORD: "api/forgotPassword",
  UPDATE_PASSWORD: "api/updatePassword",
  CHANGE_PASSWORD: "/admin/changePassword",
  LIST_ASSIGNMENTS_ORG_TALNET: "/api/listAssignments",

  REQUEST_DELETION: "admin/deleteAccountRequest",
  SEARCH_SUGGESTION: "/api/searchOnly",

  DELETE_CLIENT_DATA: "/client/deleteData",
  LIST_CLIENT_BILLING: "client/listBillings",
  ADD_EDIT_BILLING: "client/addEditBilling",

  SEND_INVOICE_EMAIL: "/admin/send-invoice-email",

  SEND_INVITE: "/admin/send-invite",
  FETCH_INVITE_DETAILS: "/admin/fetch-invitation-details",
  ACCEPT_INVITE: "/admin/accept-invite",
  DELETE_INVITE: "/admin/delete-invite",
  RESEND_INVITE: "/admin/resend-invite",
  LIST_CLIENT_INVITATIONS: "/admin/fetch-invitation-client-details",
  FETCH_ACCESS_TOKEN: "/admin/fetch-access-tokens",
};
