import React from "react";
import styles from "./styles.module.scss";
import { IMAGES } from "constants/assets";

const DeleteComponent = ({
  assignment,
  data,
  deleteClickHanlder,
  showRemove,
}) => {
  return (
    <div
      title={showRemove ? "Remove from this assignmemnt" : ""}
      className={styles.userIconContainer}
      onClick={() => {
        !assignment.isArchive && deleteClickHanlder(data);
      }}
      style={{
        visibility: ![4, 6, 7].includes(data?.status) ? "visible" : "hidden",
      }}
    >
      <img
        alt=""
        src={
          assignment?.isArchive
            ? IMAGES.RI_DISABLE_DELETE_BIN
            : IMAGES.RI_DELETE_BIN
        }
        className={`${styles.icon}`}
      />
    </div>
  );
};

export default DeleteComponent;
