import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import {
  Outlet,
  useLocation,
  useParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ROUTES } from "../../../router/routes";
import SideMenu from "../../../components/layout/sideMenu";
import { ristrictAccess } from "utils/helper";
import {
  MODULES,
  SUB_MODULES,
  PERMISSIONS,
} from "../../../constants/accessControl";
import { hasPermission, sidePanelAccess } from "utils/helper";
import { getState } from "redux/store";

const AddEditClient = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const [params] = useSearchParams();
  const source = params.get("source");
  const navigate = useNavigate();
  const [isSidePanel, setIsSidePanel] = useState(false);
  const [_user, set_user] = useState();

  //////////SIDE MENU FOR EDIT CASE IN CLINET/////

  const TAB_MENU = [
    {
      label: "Overview",
      href: ROUTES.CLIENT_DETAIL,
      tabName: "overview",
      hasAccess: hasPermission(
        MODULES.ORGANIZATIONS,
        SUB_MODULES.ORGANIZATION_OVERVIEW,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Assignments",
      href: "assignments",
      tabName: "assignments",
      hasAccess: hasPermission(
        MODULES.ORGANIZATIONS,
        SUB_MODULES.ASSIGNMENTS,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Point of Contacts",
      tabName: "contacts",
      hasAccess: hasPermission(
        MODULES.ORGANIZATIONS,
        SUB_MODULES.POINT_OF_CONTACTS,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Team Members",
      tabName: "team-members",
      hasAccess: true,
    },
    {
      label: "Contracts",
      tabName: "contracts",
      hasAccess: hasPermission(
        MODULES.ORGANIZATIONS,
        SUB_MODULES.CONTRACTS,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Invoices",
      tabName: "invoices",
      hasAccess: true,
    },
    {
      label: "Billing",
      href: "billing",
      tabName: "billing",
      hasAccess: hasPermission(
        MODULES.ORGANIZATIONS,
        SUB_MODULES.BILLING,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Comment History",
      tabName: "history",
      hasAccess: hasPermission(
        MODULES.ORGANIZATIONS,
        SUB_MODULES.CLIENT_HISTORY,
        PERMISSIONS.READ
      ),
    },
  ];

  //////////SIDE MENU FOR EDIT CASE IN CLINET IN RISTRICT CASE/////

  // const RISTRCIT_MENU = [
  //   {
  //     label: "Overview",
  //     href: ROUTES.CLIENT_DETAIL,
  //     tabName: "overview",
  //     hasAccess: true,
  //   },
  //   {
  //     label: "Billing",
  //     href: "billing",
  //     tabName: "billing",
  //     hasAccess: true,
  //   },
  //   {
  //     label: "Contracts",
  //     href: "contracts",
  //     tabName: "contracts",
  //     hasAccess: true,
  //   },
  //   {
  //     label: "Invoices",
  //     href: "invoices",
  //     tabName: "invoices",
  //     hasAccess: true,
  //   },
  // ];

  const [RISTRCIT_MENU, set_restricted_menu] = useState([
    {
      label: "Overview",
      id: "overview",
      hasAccess: true,
    },
    {
      label: "Team Members",
      id: "team-members",
      hasAccess: true,
    },
    {
      label: "Contracts",
      id: "contracts",
      hasAccess: true,
    },
    {
      label: "Invoices",
      id: "invoices",
      hasAccess: true,
    },
    {
      label: "Billing",
      id: "billing",
      hasAccess: true,
    },
    {
      label: "Assignments",
      id: "assignments-ext",
      hasAccess: true,
    },
  ]);

  const restricted_org_menu = [
    {
      label: "Overview",
      id: "overview",
      hasAccess: true,
    },
    {
      label: "Point of Contacts",
      id: "contacts",
      hasAccess: true,
    },
    {
      label: "Team Members",
      id: "team-members",
      hasAccess: true,
    },
    {
      label: "Contracts",
      id: "contracts",
      hasAccess: true,
    },
    {
      label: "Invoices",
      id: "invoices",
      hasAccess: true,
    },
    {
      label: "Billing",
      id: "billing",
      hasAccess: true,
    },
    {
      label: "Assignments",
      id: "assignments-ext",
      hasAccess: true,
    },
  ];

  const restricted_ceo_menu = [
    {
      label: "Overview",
      id: "overview",
      hasAccess: true,
    },
    {
      label: "Point of Contacts",
      id: "contacts",
      hasAccess: true,
    },
    {
      label: "Team Members",
      id: "team-members",
      hasAccess: true,
    },
    {
      label: "Contracts",
      id: "contracts",
      hasAccess: true,
    },
    {
      label: "Invoices",
      id: "invoices",
      hasAccess: true,
    },
    {
      label: "Billing",
      id: "billing",
      hasAccess: true,
    },
    {
      label: "Assignments",
      id: "assignments-ext",
      hasAccess: true,
    },
  ];

  const restricted_chro_menu = [
    {
      label: "Overview",
      id: "overview",
      hasAccess: true,
    },
    {
      label: "Point of Contacts",
      id: "contacts",
      hasAccess: true,
    },
    {
      label: "Team Members",
      id: "team-members",
      hasAccess: true,
    },
    // {
    //   label: "Contracts",
    //   id: "contracts",
    //   hasAccess: true,
    // },
    // {
    //   label: "Invoices",
    //   id: "invoices",
    //   hasAccess: true,
    // },
    // {
    //   label: "Billing",
    //   id: "billing",
    //   hasAccess: true,
    // },
    {
      label: "Assignments",
      id: "assignments-ext",
      hasAccess: true,
    },
  ];

  const restricted_cfo_menu = [
    {
      label: "Overview",
      id: "overview",
      hasAccess: true,
    },
    {
      label: "Point of Contacts",
      id: "contacts",
      hasAccess: true,
    },
    {
      label: "Team Members",
      id: "team-members",
      hasAccess: true,
    },
    {
      label: "Contracts",
      id: "contracts",
      hasAccess: true,
    },
    {
      label: "Invoices",
      id: "invoices",
      hasAccess: true,
    },
    {
      label: "Billing",
      id: "billing",
      hasAccess: true,
    },
    // {
    //   label: "Assignments",
    //   id: "assignments-ext",
    //   hasAccess: true,
    // },
  ];

  const restricted_hr_menu = [
    {
      label: "Overview",
      id: "overview",
      hasAccess: true,
    },
    {
      label: "Point of Contacts",
      id: "contacts",
      hasAccess: true,
    },
    {
      label: "Team Members",
      id: "team-members",
      hasAccess: true, //view-only
    },
    // {
    //   label: "Contracts",
    //   id: "contracts",
    //   hasAccess: true,
    // },
    // {
    //   label: "Invoices",
    //   id: "invoices",
    //   hasAccess: true,
    // },
    // {
    //   label: "Billing",
    //   id: "billing",
    //   hasAccess: true,
    // },
    {
      label: "Assignments",
      id: "assignments-ext",
      hasAccess: true, //view-only
    },
  ];

  const restricted_employee_menu = [
    {
      label: "Overview",
      id: "overview",
      hasAccess: true,
    },
    {
      label: "Point of Contacts",
      id: "contacts",
      hasAccess: true,
    },
    {
      label: "Team Members",
      id: "team-members",
      hasAccess: true,
    },
    // {
    //   label: "Contracts",
    //   id: "contracts",
    //   hasAccess: true,
    // },
    // {
    //   label: "Invoices",
    //   id: "invoices",
    //   hasAccess: true,
    // },
    // {
    //   label: "Billing",
    //   id: "billing",
    //   hasAccess: true,
    // },
    {
      label: "Assignments",
      id: "assignments-ext",
      hasAccess: true,
    },
  ];

  const getLink = (menu) => {
    if (source && menu.tabName) {
      return `/clients/overview/${id}?tab=${menu.tabName}`;
    } else if (source) {
      return `${menu.href}${id ? `/${id}` : ""}?source=overview`;
    } else {
      return `${menu.href}${id ? `/${id}` : ""}`;
    }
  };

  /////// HANLDER FOR CHEKING IS THER SIDE PANEL OR NOT//////

  useEffect(() => {
    setIsSidePanel(sidePanelAccess());
  }, []);

  useEffect(() => {
    const user = getState()?.auth?.data;
    set_user(getState()?.auth?.data);

    if (_user?.organization_role == "Organization Admin") {
      set_restricted_menu(restricted_org_menu);
    }

    if (_user?.organization_role == "CxO / Co-Founder") {
      set_restricted_menu(restricted_ceo_menu);
    }

    if (_user?.organization_role == "CHRO") {
      set_restricted_menu(restricted_ceo_menu);
    }

    if (_user?.organization_role == "CFO") {
      set_restricted_menu(restricted_ceo_menu);
    }

    if (_user?.organization_role == "HR") {
      set_restricted_menu(restricted_hr_menu);
    }

    if (_user?.organization_role == "Recruiter") {
      set_restricted_menu(restricted_hr_menu);
    }

    if (_user?.organization_role == "Non Leadership") {
      set_restricted_menu(restricted_employee_menu);
    }

    if (_user?.organization_role == "Hiring Manager") {
      set_restricted_menu(restricted_employee_menu);
    }
  }, [_user]);

  // check if user is authorized to access this route

  useEffect(() => {
    const current_user = getState()?.auth?.data;

    if (current_user.talentLabRole === "Organizations") {
      if (current_user.commonId !== id) {
        navigate("/");
      }
    }

    if (current_user.talentLabRole === "Talent") {
      navigate("/");
    }
  }, []);

  ////// RETURN ////////

  return (
    <section
      className={
        ristrictAccess() || !isSidePanel
          ? styles.ristrictContainer
          : styles.overview
      }
    >
      <div className="container">
        <div className="row">
          {!id && (
            <div className="mb-4 col-md-12">
              <div className={styles.heading}>
                <h2>Add a new organization</h2>
              </div>
            </div>
          )}{" "}
          <div className="col-md-12">
            <div className="d-flex align-items-start">
              {id && (
                <SideMenu
                  items={ristrictAccess() ? RISTRCIT_MENU : TAB_MENU}
                  onClick={(menu) => navigate(getLink(menu))}
                  isActive={(menu) => pathname?.includes(menu.href)}
                />
              )}

              <div
                className={`tab-content 
                ${styles.innerContainer} ${
                  ristrictAccess()
                    ? styles.ristrictInnerContainer
                    : styles.tabcontent
                }`}
              >
                <div className="fade show active">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddEditClient;
