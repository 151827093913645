import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { IMAGES } from "../../../../constants/assets";
import { addEditApi, listApi } from "../../../../api/commonApis";
import { APIS } from "../../../../api/endpoints";
import { useParams } from "react-router-dom";
import SearchBar from "../../../../components/searchBar";
import DefaultSorting from "../../../../components/filters/defaultsorting";
import { assignmentTalentStatus } from "../../../../constants/contants";
import { MenuItem, Select } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Skeleton from "../../../../components/skeleton";
import Checkbox from "../../../../components/table/checkbox";
import { sortingOptions } from "../../../../constants/contants";
import Pagination from "../../../../components/table/pagination";
import { toast } from "react-toastify";
import ToastMessage from "../../../../components/layout/toast";
import BasicConfirmationModal from "../../../../components/modal/basicConfirmationModal";
import { hasPermission } from "../../../../utils/helper";
import {
  MODULES,
  SUB_MODULES,
  PERMISSIONS,
} from "../../../../constants/accessControl";
import { TOAST_MESSAGE } from "constants/toastMessage";
import CommonFilter from "components/commonFilter";

const greenBackDropDown = [4, 6, 7];
const confirmationValue = [4, 7, 8, 9];

const defaultFilters = {
  experience: [],
  locations: [],
  sectors: [],
  compensation: [],
  skillRoles: [],
};

const AddCandidate = () => {
  const { id } = useParams();
  const [talent, setTalent] = useState([]);
  const [search, setSearch] = useState();
  const [assignment, setAssignment] = useState();
  const [statusChecked, setStatusChecked] = useState([]);
  const [sorting, setSorting] = useState(1);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [limitPerPage, setLimitPerPage] = useState(10);
  const [appliedFilters, setAppliedFilters] = useState(defaultFilters);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [payloadData, setPayloadData] = useState();
  const [inputValue, setInputValue] = useState();
  const [searchClicked, setSearchClicked] = useState(false);
  const [resetValue, setResetValue] = useState(false);
  const [refreshApi, SetRefreshApi] = useState(false);
  const [hasWriteAccess, setHasWriteAccess] = useState(false);

  ////// PERMISSION//////

  useEffect(() => {
    setHasWriteAccess(
      hasPermission(
        MODULES.ASSIGNMENT,
        SUB_MODULES.ADD_CANDIDATE,
        PERMISSIONS.WRITE
      )
    );
  }, []);

  /////////////////////Talent List Api Data//////////////////////////

  useEffect(() => {
    setLoading(false);
    let payload = {
      assignmentId: id,
      skip: limitPerPage * page,
      limit: limitPerPage,
    };
    if (search) {
      payload.search = search;
      payload.forAssignmentSearch = true;
    }

    if (sorting) {
      payload.sort = sorting;
    }
    if (appliedFilters?.locations.length > 0) {
      payload.locations = JSON.stringify(appliedFilters?.locations);
    }
    if (appliedFilters?.sectors.length > 0) {
      payload.sectors = JSON.stringify(appliedFilters?.sectors);
    }
    if (appliedFilters?.skillRoles.length > 0) {
      payload.skillRoles = JSON.stringify(appliedFilters?.skillRoles);
    }
    if (appliedFilters?.compensation?.length > 0) {
      const compensationObj = {
        minCompensation: JSON.stringify(appliedFilters?.compensation[0]),
        maxCompensation: JSON.stringify(appliedFilters?.compensation[1]),
      };
      payload.compensation = compensationObj;
    }
    if (appliedFilters?.experience) {
      const allYears = [...appliedFilters?.experience.flat()];
      if (allYears.length) {
        const minExperience = Math.min(...allYears);
        const maxExperience = Math.max(...allYears);

        payload.experience = {
          minExperience,
          maxExperience,
        };
      }
    }

    listApi(APIS.LIST_TALENTS, payload)
      .then((result) => {
        setAssignment(result?.data?.assignmentData);
        setTalent(result?.data?.data);
        setCount(result?.data?.count);
      })
      .finally(() => setLoading(false));
  }, [
    refreshApi,
    searchClicked,
    page,
    success,
    sorting,
    limitPerPage,
    appliedFilters,
  ]);

  /////////Handler/////////////////////
  const onPageChange = (pageNo) => {
    setPage(pageNo);
  };

  const onStatusChange = (ids, property, event, isSwitch = false) => {
    let payload = {
      assignmentId: id,
      talentId: ids,
      [property]: isSwitch ? event?.target?.checked : event?.target?.value,
    };
    if (event?.target?.value) {
      payload.statusName = assignmentTalentStatus.find(
        (o) => o.value === event?.target?.value
      )?.label;
    }

    if (confirmationValue.includes(event?.target?.value)) {
      setOpenConfirmation(true);
      setPayloadData(payload);
    } else {
      addEditApi(APIS.CHANGE_ASSIGNMENT_CANDIDATE_STATUS, payload).then(() => {
        toast.success(
          <ToastMessage
            title={"Success"}
            message={TOAST_MESSAGE.UPDATE_STATUS}
          />
        );
        setSuccess((prev) => !prev);
        setStatusChecked([]);
      });
    }
  };

  const searchHandler = (e) => {
    e?.preventDefault();
    setSearch(e.target[0].value);
  };

  const allStatusCheckHandler = (event) => {
    var updatedList = [];
    if (event.target.checked) {
      talent?.map((data) => (updatedList = [...updatedList, data?._id]));
    } else {
      updatedList.splice();
    }
    setStatusChecked(updatedList);
  };

  const statusCheckBoxHandler = (event) => {
    var updatedList = [...statusChecked];
    if (event.target.checked) {
      updatedList = [...statusChecked, event.target.id];
    } else {
      updatedList.splice(statusChecked.indexOf(event.target.id), 1);
    }
    setStatusChecked(updatedList);
  };

  const DefaultSortingHandler = (e) => {
    setSorting(e.value);
  };
  //////
  const userAddHandler = (talentId) => {
    addEditApi(APIS.ADD_ASSIGNMENT_CANDIDATES, {
      assignmentId: id,
      talentId: talentId,
      action: 1,
    }).then(() => {
      toast.success(
        <ToastMessage title={"Success"} message={TOAST_MESSAGE.ADD} />
      );
      setSuccess((prev) => !prev);
      setStatusChecked([]);
    });
  };
  /////////// to Close the basic Confirmation Modal//////

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  /////// Hanlder for MOdal ///
  const cloneConfirmHandler = () => {
    addEditApi(APIS.CHANGE_ASSIGNMENT_CANDIDATE_STATUS, payloadData).then(
      () => {
        toast.success(
          <ToastMessage
            title={"Success"}
            message={TOAST_MESSAGE.UPDATE_STATUS}
          />
        );
        setSuccess((prev) => !prev);
        setStatusChecked([]);
        handleCloseConfirmation();
      }
    );
  };

  const SearchClick = () => {
    setSearchClicked(!searchClicked);
  };

  const searchChange = (e) => {
    if (e.target.value === "") {
      setSearch("");
      SetRefreshApi(!refreshApi);
    } else {
      setSearch(e?.target?.value);
    }
  };
  return (
    <div className={`tab-content ${styles.tabMainContent}`}>
      {loading ? (
        <Skeleton height="300px" />
      ) : (
        <>
          <div className="row">
            <div className="col-md-12">
              <div className={styles.searchfilter}>
                <h2 className={styles.title}>
                  {assignment?.title ? `${assignment?.title} ` : ""}
                  {assignment?.title && assignment?.companyId?.name
                    ? ` - `
                    : ""}
                  <span className={styles.fw400}>
                    {assignment?.companyId?.name}
                  </span>
                </h2>
                <hr className={styles.divider} />
                <SearchBar
                  internalSearch
                  showBorder={true}
                  showFilters={false}
                  fullWidth={true}
                  placeholder="a candidate"
                  onSubmit={searchHandler}
                  onChange={searchChange}
                  setInputValue={setInputValue}
                  inputValue={inputValue}
                  SearchClick={SearchClick}
                  resetValue={resetValue}
                  setResetValue={setResetValue}
                />
              </div>
            </div>
          </div>

          <CommonFilter
            onChange={setAppliedFilters}
            appliedFilters={appliedFilters}
            type={4}
          />
          <hr className={styles.divider} />

          <div className="mt-3 row">
            <div className="col-md-12 d-flex justify-content-between">
              <div className={styles.rightTextDiv}>
                <div className={` ${styles.bulkText}`}>Bulk add</div>
                <div>
                  <div
                    className={styles.iconSmallCircle}
                    onClick={(e) => {
                      hasWriteAccess &&
                        !assignment?.isArchive &&
                        statusChecked.length &&
                        userAddHandler(statusChecked);
                    }}
                  >
                    <img
                      alt=""
                      src={
                        assignment?.isArchive || !hasWriteAccess
                          ? IMAGES.ADD_DISABLED_USER
                          : statusChecked.length
                          ? IMAGES.ADD_USER
                          : IMAGES.ADD_DISABLED_USER
                      }
                    />
                  </div>
                </div>
              </div>
              <div className={styles.showingResult}>
                Showing{" "}
                <span className={styles.bold}>{talent?.length || 0}</span>{" "}
                Candidates
              </div>
              <div className={styles.sortingDiv}>
                <div className={styles.sortingContainer}>
                  <DefaultSorting
                    fontSize="11px"
                    type="select"
                    value={sortingOptions.filter((i) => i.value === sorting)}
                    onChange={DefaultSortingHandler}
                    options={sortingOptions}
                    placeholder={"Default Sorting"}
                    name={"sorting"}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.tablehead} ${styles.widthselect}`}>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" className={styles.thCheckBox}>
                    <Checkbox
                      id="allselect"
                      checked={
                        statusChecked?.length === talent?.length &&
                        statusChecked?.length !== 0
                      }
                      onChange={allStatusCheckHandler}
                    />
                  </th>
                  <th scope="col" className={styles.thName}>
                    Name
                  </th>
                  <th scope="col" className={styles.thCurrentStatus}>
                    Current Assignment Status
                  </th>
                  <th scope="col" className={styles.thAddUser}></th>
                </tr>
              </thead>
              {talent?.length !== 0 && (
                <tbody>
                  {talent?.map((data, index) => {
                    return (
                      <tr
                        key={index}
                        className={
                          statusChecked.includes(data?._id)
                            ? styles.checkedRow
                            : ""
                        }
                      >
                        <td style={{ verticalAlign: "middle" }}>
                          {data?.status !== 4 && (
                            <Checkbox
                              tableStyle
                              id={data?._id}
                              checked={statusChecked.includes(data?._id)}
                              onChange={statusCheckBoxHandler}
                            />
                          )}
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          <div className={styles.tablecnames}>
                            {/* <h2>{`${data?.firstName} ${data?.lastName}`}</h2> */}
                            <a
                              target="_blank"
                              href={`/talent/overview/${data?._id}?tab=overview`}
                              className={styles.tlAssignedCandidate}
                            >{`${data?.firstName} ${data?.lastName}`}</a>
                            <p>{data?.recentWork?.designation || ""}</p>
                          </div>
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          {data?.alreadyAddedToAssignment ? (
                            <Select
                              size="small"
                              variant="standard"
                              disabled={
                                !hasWriteAccess || !!assignment?.isArchive
                              }
                              className={`${styles.topSelect} ${
                                greenBackDropDown.includes(
                                  data?.assignmentStatus
                                ) && styles.greenSelect
                              }`}
                              value={
                                data?.assignmentStatus === 3
                                  ? 2
                                  : data?.assignmentStatus
                              }
                              inputProps={{ "aria-label": "Without label" }}
                              onChange={(e) => {
                                if (e?.target?.value === "") {
                                } else {
                                  onStatusChange([data?._id], "status", e);
                                }
                              }}
                              IconComponent={ExpandMoreIcon}
                              sx={{
                                "&	.MuiSelect-select": {
                                  padding: "0px 0px 0px 7px",
                                  fontSize: "10px",
                                  fontWeight: "bold",
                                  ":focus": {
                                    backgroundColor: "transparent",
                                  },
                                },
                              }}
                            >
                              {assignmentTalentStatus.map((item, index) => (
                                <MenuItem
                                  key={index}
                                  value={item?.value}
                                  disabled={item?.value === ""}
                                  className={
                                    item?.value === ""
                                      ? styles.trasnStyles
                                      : styles.selectItem
                                  }
                                >
                                  {item?.label}
                                </MenuItem>
                              ))}
                            </Select>
                          ) : (
                            ""
                          )}
                        </td>

                        <td
                          className={
                            data?.status === 4 || data?.alreadyAddedToAssignment
                              ? styles.iconTdContaner
                              : styles.iconNewContaner
                          }
                        >
                          {data?.status === 4 ? (
                            <div className={styles.addedTextOfflimitCss}>
                              Off limit
                            </div>
                          ) : data?.alreadyAddedToAssignment ? (
                            "Added to Assignment"
                          ) : (
                            <div
                              onClick={() => {
                                !assignment?.isArchive &&
                                  userAddHandler([data?._id]);
                              }}
                              className={styles.userIconContainer}
                            >
                              {hasWriteAccess && (
                                <img
                                  alt=""
                                  src={
                                    assignment?.isArchive
                                      ? IMAGES.ADD_DISABLED_USER
                                      : IMAGES.ADD_USER
                                  }
                                  className={styles.tableAddUser}
                                />
                              )}
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
            {talent?.length === 0 && (
              <div className={styles.nodataStyle}>No candidates yet.</div>
            )}
            <BasicConfirmationModal
              openConfirmation={openConfirmation}
              closeModal={handleCloseConfirmation}
              cloneConfirmHandler={cloneConfirmHandler}
              data="Are you sure you want to change the status?"
              buttonLabel="Yes"
            />
            {talent?.length !== 0 && (
              <Pagination
                tablePagination
                totalCount={count}
                currentPage={page}
                limitPerPage={limitPerPage}
                onPageChange={onPageChange}
                setLimitPerPage={setLimitPerPage}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default AddCandidate;
