import { BASE_URL } from "../api";

export const IMAGE_BASE_URL = `${BASE_URL}/bucket/`;

export const monthOption = [
  { label: "January", value: "january" },
  { label: "February", value: "february" },
  { label: "March", value: "march" },
  { label: "April", value: "april" },
  { label: "May", value: "may" },
  { label: "June", value: "june" },
  { label: "July", value: "july" },
  { label: "August", value: "august" },
  { label: "September", value: "september" },
  { label: "October", value: "october" },
  { label: "November", value: "november" },
  { label: "December", value: "december" },
];
export const yearOption = [
  { label: "Select", value: "" },
  { label: "2010", value: "2010" },
  { label: "2011", value: "2011" },
  { label: "2012", value: "2012" },
  { label: "2013", value: "2013" },
  { label: "2014", value: "2014" },
  { label: "2015", value: "2015" },
  { label: "2016", value: "2016" },
  { label: "2017", value: "2017" },
  { label: "2018", value: "2018" },
  { label: "2019", value: "2019" },
  { label: "2020", value: "2020" },
  { label: "2021", value: "2021" },
];
export const currencyOption = [
  { label: "Select", value: "" },
  { label: "Currency 1", value: "currency 1" },
  { label: "Currency 2", value: "currency 2" },
  { label: "Currency 3", value: "currency 3" },
  { label: "Currency 4", value: "currency 4" },
];

export const educationTypeOptions = [
  { value: "", label: "Select" },
  { value: "post graduation", label: "Post Graduation" },
  { value: "m phill", label: "M Phill" },
];
// export const editorApiKey = "dxjo213hjt1wshbuab85nevoef38tclcr9gmx69p83f3r7ms";

export const editorApiKey = "m407agbdo9luy0mfkh5id43ubd50qxye5lu7bxyvsmaw1b6n";

export const editorSettings = {
  plugins: [
    "advlist",
    "autolink",
    "lists",
    "link",
    "image",
    "charmap",
    "preview",
    "anchor",
    "searchreplace",
    "visualblocks",
    "code",
    "fullscreen",
    "insertdatetime",
    "media",
    "table",
    "code",
    "help",
    "wordcount",
  ],
  toolbar:
    "undo redo | blocks | " +
    "bold italic forecolor | alignleft aligncenter " +
    "alignright alignjustify | bullist numlist outdent indent | " +
    "removeformat | help",
  content_style:
    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
};

export const dayOption = [
  { label: "10 Days", value: 10 },
  { label: "15 Days", value: 15 },
  { label: "30 Days", value: 30 },
  { label: "45 Days", value: 45 },
  { label: "55 Days", value: 55 },
  { label: "60 Days", value: 60 },
];

export const saveAddNewButtonLabels = {
  workSave: "Save & Add New Work",
  educationSave: "Save & Add New Education",
  certsSave: "Save & Add New Certification",
  publicationsSave: "Save & Add New Publication",
};
export const saveViewButtonLabels = {
  workSave: "Save & View Work",
  educationSave: "Save & View Education",
  saveAndViewCert: "Save & View Certification",
  saveAndViewPublication: `Save & View Publication`,
  compansationSave: "Save & View Compensation",
};

export const noDataLabels = {
  educationList:
    "No education qualification added yet, click on add new to get started.",
  workList: "No work experience added yet, click on add new to get started.",
  compensation: "No compensation details added yet.",
  certificationList: "No certification and publication added yet.",
  dossierTable: "No dossier added yet.",
};

export const emptyTableText = {
  talentDocument: "No documents uploaded yet.",
  organisationOverView: "No assignment to show.", // You have been not been mapped to any assignment yet.
  organisationAssignment: "No assignment to show.",
  organisationContract: "No contracts uploaded yet.",
  organisationInvoices: "No invoices uploaded yet.",
  assignmentOverview: "No candidates assigned yet.",
  contactPersons:
    "No records exist. Click on the edit button above to add contact.",
};

export const COMMENT_TYPE = {
  TALENT: 1,
  ORGANIZATION: 2,
  ASSIGNMENT: 3,
};

export const FILTER_TYPE = {
  TALENT: 1,
  ASSIGNMENT: 2,
  ORGANIZATION: 3,
};

// the below constant is used for the status of the talent in an assignment
// they are not in order because they have been updated and remapped
export const assignmentTalentStatus = [
  { label: "PeopleAsset Statuses", value: "" }, // break
  { label: "Under review", value: 1 },
  { label: "On hold", value: 5 },
  { label: "Dropped after PA evaluation", value: 9 },
  { label: "Profile received & PA evaluation drop", value: 10 },
  { label: "Not interested", value: 11 },
  { label: "Candidate interested & in active discussions", value: 12 },
  { label: "Profile received & PA evaluation pending", value: 13 },
  { label: "Profile received & PA evaluation shortlist", value: 14 },
  { label: "Dossier has been shared", value: 15 },
  { label: "Client Statuses", value: "" }, // break

  { label: "Interviews / Assessment stage", value: 2 },
  { label: "Offer has been rolled out", value: 4 },
  { label: "Offer accepted & DOJ confirmed", value: 6 },
  { label: "Candidate onboarded", value: 7 },
  { label: "Offer declined", value: 8 },
  { label: "Profile review shortlisted", value: 16 },
  { label: "Profile review dropped", value: 17 },
  // { label: "Interviews / Assessment stage", value: 3 },
  { label: "Final round interview stage", value: 18 },
  { label: "Interview reject", value: 19 },
  { label: "Offer discussions stage", value: 20 },
  { label: "Accepted offer & dropped out", value: 21 },
  { label: "Handholding stage", value: 22 },
  { label: "Position closed", value: 23 },
];
// {
//   label: "Select",
//   value: "",
// },
// {
//   label: "Assigned",
//   value: 1,
// },
// {
//   label: "Benched",
//   value: 5,
// },
// {
//   label: "Dropped After Conversion",
//   value: 9,
// },
// {
//   label: "Interviewed",
//   value: 3,
// },
// {
//   label: "Joined",
//   value: 7,
// },
// {
//   label: "Not Selected",
//   value: 10,
// },

// {
//   label: "Offer Made",
//   value: 4,
// },

// {
//   label: "Offer Accepted",
//   value: 6,
// },

// {
//   label: "Offer Dropped",
//   value: 8,
// },

// {
//   label: "To be interviewed",
//   value: 2,
// },

export const assignmentStatus = [
  { label: "Completed", value: 12 },
  { label: "Converted", value: 9 },
  { label: "Candidate joining", value: 10 },
  { label: "Discussion deck", value: 2 },
  { label: "Dossier Submission", value: 5 },
  { label: "Eng. Progress", value: 4 },
  { label: "Handholding", value: 8 },
  { label: "Interviews", value: 6 },
  { label: "Job Description", value: 1 },
  { label: "Offer stage", value: 7 },
  { label: "Post joining eng.", value: 11 },
  { label: "Weekly cadence", value: 3 },
];

export const assignmentFilter = [
  { label: "Job Description", value: 1 },
  { label: "Discussion deck", value: 2 },
  { label: "Weekly cadence", value: 3 },
  { label: "Eng. Progress", value: 4 },
  { label: "Dossier Submission", value: 5 },
  { label: "Interviews", value: 6 },
  { label: "Offer stage", value: 7 },
  { label: "Handholding", value: 8 },
  { label: "Converted", value: 9 },

  // { label: "Candidate joining", value: 10 },
  // { label: "Post joining eng.", value: 11 },
  // { label: "Completed", value: 12 },
];
export const sortingOptions = [
  { label: "Default Sorting", value: 1 },
  { label: `Alphabetic (A \u2192	Z)`, value: 2 },
  { label: "Alphabetic (Z \u2192 A)", value: 3 },
  { label: "Experience (More \u2192 Less)", value: 4 },
  { label: "Experience (Less \u2192 More)", value: 5 },
  { label: "Date Created  (Latest First)", value: 6 },
  { label: "Date Created (Oldest First)", value: 7 },
];
export const AssAndOrgSortingOptions = [
  { label: "Default Sorting", value: 1 },
  { label: "Alphabetic (A \u2192 Z)", value: 2 },
  { label: "Alphabetic (Z \u2192 A)", value: 3 },
  { label: "Date Created  (Latest First)", value: 4 },
  { label: "Date Created (Oldest First)", value: 5 },
];

export const talentFilterButton = [
  { label: "Not Assigned", value: 1 },
  { label: "Converted", value: 2 },
  { label: "In Pipeline", value: 3 },
  { label: "Off Limit", value: 4 },
  { label: "Joined", value: 5 },
];

export const SEARCH_TAB_TYPE = {
  talent: 1,
  organisation: 2,
  assignment: 3,
};

export const timeSelect = [
  {
    label: "10 minutes before",
    value: 10,
  },
  {
    label: "20 minutes before",
    value: 20,
  },
  {
    label: "30 minutes before",
    value: 30,
  },
];

export const durationSelect = [
  {
    label: "Don’t Repeat",
    value: 4,
  },
  {
    label: "Daily",
    value: 1,
  },
  {
    label: "Weekly",
    value: 2,
  },
  {
    label: "Monthly",
    value: 3,
  },
];

export const durationOptions = [
  {
    label: "15 Mins",
    value: 15,
  },
  {
    label: "30 Mins",
    value: 30,
  },
  {
    label: "1 Hour",
    value: 60,
  },
  {
    label: "2 Hour",
    value: 120,
  },
  {
    label: "3 Hour",
    value: 180,
  },
];

export const ARCHIVE_MODAL_CONFIRMATION =
  "Type ARCHIVE to confirm archive Assignment";
export const REMOVE_MODAL_CONFIRMATION = "Type REMOVE to confirm removal";
export const DELETE_MODAL_CONFIRMATION = "Type DELETE to confirm deletion";
export const DELETE_ORGANISATION_DESCRIPTION =
  "This will delete all its associated assignemnts and records.";
export const DELETE_CANDIDATE_DESCRIPTION =
  "This will delete all its associated candidate and records.";
export const ARCHIVED_DESCRIPTION =
  "This will delete all its associated assignemnts and records.";
export const STATUS_CHANGE = "Are you sure you want to change the status?";

export const compentancyOptions = [
  { label: ` 0 to 1 `, value: "1" },
  { label: ` 1 to 2 `, value: "2" },
  { label: ` 2 to 3 `, value: "3" },
  { label: ` 3 to 4 `, value: "4" },
];

/*Profile=>Talent Lab User All Constant */

export const TALENT_SUB_MODULE = [
  {
    label: "Talent Overview",
    value: "talentOverView",
    code: "TalentOverVieW",
    isEnable: true,
  },
  {
    label: "Work",
    value: "work",
    code: "WorK",
    isEnable: true,
  },
  {
    label: "Education",
    value: "education",
    code: "EducatioN",
    isEnable: true,
  },
  {
    label: "Documents",
    value: "documents",
    code: "DocumentS",
    isEnable: true,
  },
  {
    label: "Compensation",
    value: "compensation",
    code: "CompensatioN",
    isEnable: true,
  },
  {
    label: "Certs & Publications",
    value: "certsAndPublications",
    code: "CertsAndPublicationS",
    isEnable: true,
  },
  {
    label: "Compentency Mapping ",
    value: "compentencyMapping",
    code: "CompentencyMappinG",
    isEnable: true,
  },
  {
    label: "Candidate Dossier",
    value: "candidateDossier",
    code: "CandidateDossieR",
    isEnable: true,
  },
  {
    label: "Candidate History",
    value: "candidateHistory",
    code: "CandidateHistorY",
    isEnable: true,
  },
];
export const ORAGANIZATION_SUB_MODULE = [
  {
    label: "Organization Overview",
    value: "organizationOverView",
    code: "OrganizationOverVieW",
    isEnable: true,
  },
  {
    label: "Assignments",
    value: "assignments",
    code: "AssignmentS",
    isEnable: true,
  },

  { label: "Contracts", value: "contracts", code: "ContractS", isEnable: true },
  { label: "Billing", value: "billing", code: "BillinG", isEnable: true },
  {
    label: "Point Of Contact",
    value: "pointOfContact",
    code: "PointOfContacT",
    isEnable: true,
  },
  {
    label: "Comment History",
    value: "commentHistory",
    code: "ClientHistorY",
    isEnable: true,
  },
];

export const ASSIGNMENT_SUB_MODULE = [
  {
    label: "Assignment Overview",
    value: "assignmentOverView",
    code: "AssignmnentOverVieW",
    isEnable: true,
  },

  {
    label: "Assigned Candidates",
    value: "assignedCandidates",
    code: "AssignedCandidateS",
    isEnable: false,
  },
  {
    label: "Add Candidates",
    value: "addCandidates",
    code: "AddCandidateS",
    isEnable: false,
  },
  {
    label: "Assignment History",
    value: "assignmentHistory",
    code: "AssignmentHistorY",
    isEnable: false,
  },
  {
    label: "Activity Log",
    value: "activityLog",
    code: "ActivityLoG",
    isEnable: false,
  },
];
export const DATA_SET_MODULE = [
  {
    label: "User Managment",
    value: "userManagment",
    code: "UserManagmenT",
    isEnable: true,
  },
  {
    label: "Functional Areas",
    value: "functionalAreas",
    code: "FunctionalAreaS",
    isEnable: true,
  },

  {
    label: "Industry Types",
    value: "industryTypes",
    code: "IndustryTypeS",
    isEnable: true,
  },
  { label: "Sectors", value: "sectors", code: "SectorS", isEnable: true },
  {
    label: "Skills Tags",
    value: "skillsTags",
    code: "SkillsTagS",
    isEnable: true,
  },
  {
    label: "Competencies",
    value: "competencies",
    code: "CompetencieS",
    isEnable: true,
  },
  {
    label: "Education Types",
    value: "educationTypes",
    code: "EducationTypes",
    isEnable: true,
  },
];

export const OVERVIEW_SUB_MODULE = [
  // {
  //   label: "OverView",
  //   value: "overviewOverView",
  //   code: "OverviewOverVieW",
  // },
];
export const SEARCH_SUB_MODULE = [
  // {
  //   label: "Search OverView",
  //   value: "SearchOverView",
  //   code: "SearchOverVieW",
  // },
];

export const ACCESS_PRIVILEGES = [
  // {
  //   heading: "Overview",
  //   objectData: OVERVIEW_SUB_MODULE,
  //   ObjectCheck: "overViewChecked",
  // },
  // {
  //   heading: "Search",
  //   objectData: SEARCH_SUB_MODULE,
  //   ObjectCheck: "searchChecked",
  // },
  {
    heading: "Talent",
    objectData: TALENT_SUB_MODULE,
    ObjectCheck: "talentChecked",
  },
  {
    heading: "Organizations",
    objectData: ORAGANIZATION_SUB_MODULE,
    ObjectCheck: "organizationChecked",
  },
  {
    heading: "Assignment",
    objectData: ASSIGNMENT_SUB_MODULE,
    ObjectCheck: "assignmentChecked",
  },
  {
    heading: "Data Sets",
    objectData: DATA_SET_MODULE,
    ObjectCheck: "dataSetChecked",
  },
];

export const ROLES = {
  SUPER_ADMIN: "Super Admin",
  ORGANIZATIONS: "Organizations",
  TALENT: "Talent",
};

export const ROLES_OPTIONS = [
  { label: "Super Admin", value: "Super Admin" },
  { label: "Admin", value: "Admin" },
  { label: "Consultant", value: "Consultant" },
  { label: "Finance Manager", value: "Finance Manager" },
  { label: "Organizations", value: "Organizations" },
  { label: "Talent", value: "Talent" },
  { label: "Research Associate", value: "Research Associate" },
];

export const ORG_ROLES_OPTIONS = [
  { label: "Organization Admin", value: "Organization Admin" },
  { label: "CxO / Co-Founder", value: "CxO / Co-Founder" },
  { label: "CHRO", value: "CHRO" },
  { label: "CFO", value: "CFO" },
  { label: "HR", value: "HR" },
  { label: "Recruiter", value: "Recruiter" },
  { label: "Hiring Manager", value: "Hiring Manager" },
  { label: "Non Leadership", value: "Non Leadership" },
];

export const CONSULTANT_ROLES_OPTIONS = [
  { label: "Consultant", value: "Consultant" },
  { label: "Organizations", value: "Organizations" },
  { label: "Talent", value: "Talent" },
];

export const SUPER_ADMIN_ACCESS = [
  // {
  //   module: "Overview",
  //   permission: {
  //     overviewOverView: ["read", "write", "delete"],
  //   },
  // },
  // {
  //   module: "Search",
  //   permission: {
  //     SearchOverView: ["read", "write", "delete"],
  //   },
  // },
  {
    module: "Talent",
    permission: {
      talentOverView: ["read", "write", "delete"],
      work: ["read", "write", "delete"],
      education: ["read", "write", "delete"],
      documents: ["read", "write", "delete"],
      compensation: ["read", "write", "delete"],
      certsAndPublications: ["read", "write", "delete"],
      compentencyMapping: ["read", "write", "delete"],
      candidateDossier: ["read", "write", "delete"],
      candidateHistory: ["read", "write", "delete"],
    },
  },
  {
    module: "Organizations",
    permission: {
      organizationOverView: ["read", "write", "delete"],
      contracts: ["read", "write", "delete"],
      assignments: ["read", "write", "delete"],
      billing: ["read", "write", "delete"],
      clientHistory: ["read", "write", "delete"],
      pointOfContact: ["read", "write", "delete"],
    },
  },
  {
    module: "Assignment",
    permission: {
      assignmentOverView: ["read", "write", "archive"],
      assignedCandidates: ["read", "write"],
      addCandidates: ["read", "write"],
      assignmentHistory: ["read", "write"],
      activityLog: ["read", "write"],
    },
  },
  {
    module: "Data Sets",
    permission: {
      userManagment: ["read", "write", "delete"],
      functionalAreas: ["read", "write", "delete"],
      industryTypes: ["read", "write", "delete"],
      sectors: ["read", "write", "delete"],
      skillsTags: ["read", "write", "delete"],
      competencies: ["read", "write", "delete"],
      educationTypes: ["read", "write", "delete"],
    },
  },
];

export const ADMIN_ACCESS = [
  // {
  //   module: "Overview",
  //   permission: {
  //     overviewOverView: ["read"],
  //   },
  // },
  // {
  //   module: "Search",
  //   permission: {
  //     SearchOverView: ["read"],
  //   },
  // },
  {
    module: "Talent",
    permission: {
      talentOverView: ["read", "write", "delete"],
      work: ["read", "write", "delete"],
      education: ["read", "write", "delete"],
      documents: ["read", "write", "delete"],
      compensation: ["read", "write", "delete"],
      certsAndPublications: ["read", "write", "delete"],
      compentencyMapping: ["read", "write", "delete"],
      candidateDossier: ["read", "write", "delete"],
      candidateHistory: ["read", "write", "delete"],
    },
  },
  {
    module: "Organizations",
    permission: {
      organizationOverView: ["read", "write", "delete"],
      contracts: [],
      assignments: ["read", "write", "delete"],
      billing: [],
      clientHistory: [],
      pointOfContact: ["read", "write", "delete"],
    },
  },
  {
    module: "Assignment",
    permission: {
      assignmentOverView: ["read", "write", "archive"],
      assignedCandidates: ["read", "write"],
      addCandidates: ["read", "write"],
      assignmentHistory: ["read", "write"],
      activityLog: ["read", "write"],
    },
  },
  {
    module: "Data Sets",
    permission: {
      userManagment: [],
      functionalAreas: ["read", "write", "delete"],
      industryTypes: ["read", "write", "delete"],
      sectors: ["read", "write", "delete"],
      skillsTags: ["read", "write", "delete"],
      competencies: [],
      educationTypes: ["read", "write", "delete"],
    },
  },
];

export const CONSULTANT_ACCESS = [
  // {
  //   module: "Overview",
  //   permission: {
  //     overviewOverView: ["read"],
  //   },
  // },
  // {
  //   module: "Search",
  //   permission: {
  //     SearchOverView: ["read"],
  //   },
  // },
  {
    module: "Talent",
    permission: {
      talentOverView: ["read", "write", "delete"],
      work: ["read", "write"],
      education: ["read", "write"],
      documents: ["read", "write"],
      compensation: ["read", "write"],
      certsAndPublications: ["read", "write"],
      compentencyMapping: ["read", "write"],
      candidateDossier: ["read", "write"],
      candidateHistory: ["read", "write"],
    },
  },
  {
    module: "Organizations",
    permission: {
      organizationOverView: ["read", "write"],
      contracts: [],
      assignments: ["read"],
      billing: [],
      clientHistory: [],
      pointOfContact: ["read"],
    },
  },
  {
    module: "Assignment",
    permission: {
      assignmentOverView: ["read"],
      assignedCandidates: ["read", "write"],
      addCandidates: ["read", "write"],
      assignmentHistory: ["read"],
      activityLog: ["read"],
    },
  },
  {
    module: "Data Sets",
    permission: {
      userManagment: [],
      functionalAreas: [],
      industryTypes: [],
      sectors: [],
      skillsTags: [],
      competencies: [],
      educationTypes: [],
    },
  },
];
export const FINANCE_MANAGER_ACCESS = [
  // {
  //   module: "Overview",
  //   permission: {
  //     overviewOverView: ["read"],
  //   },
  // },
  // {
  //   module: "Search",
  //   permission: {
  //     SearchOverView: ["read"],
  //   },
  // },
  {
    module: "Talent",
    permission: {
      talentOverView: [],
      work: [],
      education: [],
      documents: [],
      compensation: [],
      certsAndPublications: [],
      compentencyMapping: [],
      candidateDossier: [],
      candidateHistory: [],
    },
  },
  {
    module: "Organizations",
    permission: {
      organizationOverView: ["read"],
      contracts: ["read", "write"],
      assignments: [],
      billing: ["read", "write"],
      clientHistory: [],
      pointOfContact: [],
    },
  },
  {
    module: "Assignment",
    permission: {
      assignmentOverView: [],
      assignedCandidates: [],
      addCandidates: [],
      assignmentHistory: [],
      activityLog: [],
    },
  },
  {
    module: "Data Sets",
    permission: {
      userManagment: [],
      functionalAreas: [],
      industryTypes: [],
      sectors: [],
      skillsTags: [],
      competencies: [],
      educationTypes: [],
    },
  },
];

/*Default Access for Research Associate */

export const RESEARCH_ASSOCIATE_ACCESS = [
  // {
  //   module: "Overview",
  //   permission: {
  //     overviewOverView: ["read"],
  //   },
  // },
  // {
  //   module: "Search",
  //   permission: {
  //     SearchOverView: ["read"],
  //   },
  // },
  {
    module: "Talent",
    permission: {
      talentOverView: ["read", "write"],
      work: ["read", "write"],
      education: ["read", "write"],
      documents: ["read", "write"],
      compensation: ["read", "write"],
      certsAndPublications: ["read", "write"],
      compentencyMapping: ["read", "write"],
      candidateDossier: ["read", "write"],
      candidateHistory: ["read", "write"],
    },
  },
  {
    module: "Organizations",
    permission: {
      organizationOverView: ["read", "write"],
      contracts: [],
      assignments: ["read", "write"],
      billing: [],
      clientHistory: [],
      pointOfContact: [],
    },
  },
  {
    module: "Assignment",
    permission: {
      assignmentOverView: ["read"],
      assignedCandidates: ["read", "write"],
      addCandidates: ["read", "write"],
      assignmentHistory: ["read"],
      activityLog: ["read"],
    },
  },
  {
    module: "Data Sets",
    permission: {
      userManagment: [],
      functionalAreas: [],
      industryTypes: [],
      sectors: [],
      skillsTags: [],
      competencies: [],
      educationTypes: [],
    },
  },
];

export const GENDER_OPTIONS = [
  { label: "Male", value: 1 },
  { label: "Female", value: 2 },
  { label: "Other", value: 3 },
];

export const DATA_SET_CASE = {
  INDUSTRY_TYPE: "datasets-industrytype",
  SECTORS: "datasets-sectors",
  FUNCTIONAL_AREAS: "datasets-functionalareas",
  SKILLS_TAGS: "datasets-skillstags",
  COMPENTENCY: "datasets-compentency",
  EDUCATION_TYPE: "datasets-education",
};

export const USER_SET = {
  ADMIN: "tl-admins",
  TALENTS: "tl-talents",
  TALENT_USERS: "tl-talent-users",
  ORGS: "tl-orgs",
};

export const TAB_ROUTES = [
  "datasets-industrytype",
  "datasets-sectors",
  "datasets-functionalareas",
  "datasets-skillstags",
  "datasets-compentency",
  "datasets-education",
];

export const ASSIGNMENT_SUMMARY_BOX = [
  { title: "Total candidates", value: "totalCandidates" },
  { title: "Dossiers submitted", value: "dossierSubmitted" },
  { title: "Interview stage", value: "interviewd" },
  { title: "Candidates dropped", value: "benched" },
  { title: "Offer accepted", value: "potentialHires" },
];

export const DOSSIER_TABLE_HEADING = {
  th1: "File Name",
  th2: "Client",
  th3: "Generated By",
  th5: "",
};

export const AcceptedFormat = {
  "application/pdf": [".pdf"],
  "application/msword": [".doc", ".docx", ".pptx", ".xls", ".xlsx", ".csv"],
  "application/image": [".png", ".jpg", ".jpeg"],
  "application/testFile": [".txt"],
};

export const NO_DATA_FOUND_LIST = {
  TALENT_LIST: "No talents found",
  ORG_LIST: "No organizations found",
  ASSIGNMENT_LIST: "No assignments found",
};
