import React, { useLayoutEffect, useEffect } from "react";
import { ROUTES } from "./routes";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { PrivateLayout, PublicLayout } from "./layout";
import {
  LoginPage,
  OverviewPage,
  SearchPage,
  TalentPage,
  ClientsPage,
  ClientsOverview,
  AssignmentsPage,
  Profile,
  ProfileDetail,
  TalentLab,
  TalentOverview,
  AddEditTalentLayout,
  TalentAddEditProfile,
  TalentAddEditWork,
  TalentAddEditEducation,
  TalentAddEditDocuments,
  TalentAddEditCompensation,
  TalentAddEditCertsAndPublications,
  TalentAddEditCandidateDossier,
  TalentAddEditCompentancyMapping,
  AddEditClientLayout,
  AddEditClientOverview,
  AddEditClientBilling,
  AddEditClientContract,
  AssignmentsOverview,
  AddEditAssignmentsLayout,
  AddAssignment,
  DocViewer,
  ForgetPassowd,
  ResetPassword,
  LoginExtPage,
  SignUpExtPage,
  ForgetPassowdExt,
  ResetPasswordExt,
  ErrorPage,
} from "../pages";

import { hasPermission } from "../utils/helper";
import { MODULES, SUB_MODULES, PERMISSIONS } from "../constants/accessControl";
import { useSelector } from "react-redux";
import { ROLES } from "constants/contants";
import { ristrictAccess } from "../utils/helper";
import InvitationSignUp from "pages/invitationSignUp.js";
import TermsAndConditions from "pages/policies/terms-and-conditions";
import PrivacyPolicy from "pages/policies/privacy-policy";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { checkApi } from "../redux/actions/checkActions";

//Scroll to top on every route change
const ScrollToTop = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

const Router = () => {
  const userRole = useSelector((state) => state?.auth?.data?.talentLabRole);

  const location = useLocation();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkApi());
  }, [location.pathname, searchParams, dispatch]);

  return (
    <ScrollToTop>
      <Routes>
        <Route element={<PublicLayout />}>
          <Route path={ROUTES.LOGIN} element={<LoginPage />} />
          {/* OLD PAGES FOR RESTE AND FORGET PASSWODR */}

          {/* <Route path={ROUTES.FORGET_PASSWORD} element={<ForgetPassowd />} /> */}
          {/* <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} /> */}
          <Route
            path={ROUTES.CLIENT_LOGIN}
            element={<LoginExtPage isTalent={false} />}
          />
          <Route
            path={ROUTES.TALENT_LOGIN}
            element={<LoginExtPage isTalent={true} />}
          />

          <Route
            path={ROUTES.TALENT_SIGNUP}
            element={<SignUpExtPage isTalent={true} />}
          />
          <Route
            path={ROUTES.INVITATION}
            element={<InvitationSignUp isTalent={false} />}
          />
          <Route path={ROUTES.FORGET_PASSWORD} element={<ForgetPassowdExt />} />
          <Route path={ROUTES.RESET_PASSWORD} element={<ResetPasswordExt />} />
          <Route path={ROUTES.SET_PASSWORD} element={<ResetPasswordExt />} />
        </Route>

        <Route element={<PrivateLayout />}>
          <Route path={ROUTES.OVERVIEW} element={<OverviewPage />} />
          <Route path={ROUTES.SEARCH} element={<SearchPage />} />

          {userRole !== ROLES.ORGANIZATIONS && (
            <Route path={ROUTES.TALENT}>
              <Route path={ROUTES.TALENT} element={<TalentPage />} />
              <Route
                path={ROUTES.TALENT_OVERVIEW}
                element={<TalentOverview />}
              />

              <Route path={ROUTES.TALENT} element={<AddEditTalentLayout />}>
                <Route path={ROUTES.PROFILE}>
                  <Route index element={<TalentAddEditProfile />}></Route>

                  {ristrictAccess() ||
                  hasPermission(
                    MODULES.TALENT,
                    SUB_MODULES.TALENT_OVERVIEW,
                    PERMISSIONS.WRITE
                  ) ? (
                    <Route
                      path={ROUTES.ID}
                      element={<TalentAddEditProfile />}
                    ></Route>
                  ) : (
                    ""
                  )}
                </Route>
                {ristrictAccess() ||
                hasPermission(
                  MODULES.TALENT,
                  SUB_MODULES.WORK,
                  PERMISSIONS.WRITE
                ) ? (
                  <Route path={ROUTES.WORK} element={<TalentAddEditWork />} />
                ) : (
                  ""
                )}
                {ristrictAccess() ||
                hasPermission(
                  MODULES.TALENT,
                  SUB_MODULES.EDUCATION,
                  PERMISSIONS.WRITE
                ) ? (
                  <Route
                    path={ROUTES.EDUCATION}
                    element={<TalentAddEditEducation />}
                  />
                ) : (
                  ""
                )}
                {ristrictAccess() ||
                hasPermission(
                  MODULES.TALENT,
                  SUB_MODULES.COMPENSATION,
                  PERMISSIONS.WRITE
                ) ? (
                  <Route
                    path={ROUTES.COMPENSATION}
                    element={<TalentAddEditCompensation />}
                  />
                ) : (
                  ""
                )}

                {ristrictAccess() ||
                hasPermission(
                  MODULES.TALENT,
                  SUB_MODULES.CERT_PUBLICATIONS,
                  PERMISSIONS.WRITE
                ) ? (
                  <Route
                    path={ROUTES.CERT_PUBLICATIONS}
                    element={<TalentAddEditCertsAndPublications />}
                  />
                ) : (
                  ""
                )}
                {ristrictAccess() ||
                hasPermission(
                  MODULES.TALENT,
                  SUB_MODULES.DOCUMENTS,
                  PERMISSIONS.WRITE
                ) ? (
                  <Route
                    path={ROUTES.DOCUMENTS}
                    element={<TalentAddEditDocuments />}
                  />
                ) : (
                  ""
                )}

                <Route
                  path={ROUTES.CANDIDATE_DOSSIER}
                  element={<TalentAddEditCandidateDossier />}
                />

                <Route
                  path={ROUTES.COMPENTANCY_MAPPING}
                  element={<TalentAddEditCompentancyMapping />}
                />
              </Route>
            </Route>
          )}
          {userRole !== ROLES.TALENT && (
            <Route path={ROUTES.CLIENTS}>
              <Route path={ROUTES.CLIENTS} element={<ClientsPage />} />
              <Route
                path={ROUTES.CLIENT_OVERVIEW}
                element={<ClientsOverview />}
              />

              <Route path={ROUTES.CLIENTS} element={<AddEditClientLayout />}>
                <Route path={ROUTES.CLIENT_DETAIL}>
                  <Route index element={<AddEditClientOverview />}></Route>
                  {ristrictAccess() ||
                  hasPermission(
                    MODULES.ORGANIZATIONS,
                    SUB_MODULES.ORGANIZATION_OVERVIEW,
                    PERMISSIONS.WRITE
                  ) ? (
                    <Route
                      path={ROUTES.ID}
                      element={<AddEditClientOverview />}
                    />
                  ) : (
                    ""
                  )}
                </Route>

                <Route
                  path={ROUTES.BILLING}
                  element={<AddEditClientBilling />}
                />
                <Route
                  path={ROUTES.CONTRACT}
                  element={<AddEditClientContract />}
                />
              </Route>
            </Route>
          )}
          <Route path={ROUTES.USER_PROFILE} element={<Profile />}>
            <Route path={ROUTES.PROFILE_DETAILS} element={<ProfileDetail />} />
            <Route path={ROUTES.PROFILE_TALENTLAB} element={<TalentLab />} />
          </Route>
          <Route path={ROUTES.ASSIGNMENTS}>
            <Route path={ROUTES.ASSIGNMENTS} element={<AssignmentsPage />} />
            {hasPermission(
              MODULES.ASSIGNMENT,
              SUB_MODULES.ASSIGNMENT_OVERVIEW,
              PERMISSIONS.WRITE
            ) && (
              <Route
                path={ROUTES.ASSIGNMENTS}
                element={<AddEditAssignmentsLayout />}
              >
                <Route
                  path={ROUTES.ADD_ASSIGNMENT}
                  element={<AddAssignment />}
                />
                <Route
                  path={ROUTES.EDIT_ASSIGNMENT}
                  element={<AddAssignment />}
                />
              </Route>
            )}

            <Route
              path={ROUTES.ASSIGNMENTS_OVERVIEW}
              element={<AssignmentsOverview />}
            />
          </Route>
        </Route>

        <Route path={ROUTES.TERMS} element={<TermsAndConditions />} />
        <Route path={ROUTES.PRIVACY} element={<PrivacyPolicy />} />

        <Route path={ROUTES.DOC_VIEWER} element={<DocViewer />} />
        <Route path={ROUTES.ERROR_PAGE} element={<ErrorPage />} />
        {/* <Route path={ROUTES.TERMS} element={<TermsAndConditions />} />
          <Route path={ROUTES.PRIVACY} element={<PrivacyPolicy />} /> */}
        <Route path="*" element={<Navigate to={ROUTES.LOGIN} />} />
      </Routes>
    </ScrollToTop>
  );
};

export default Router;
