import React from "react";
import styles from "./styles.module.scss";
import { IMAGES } from "../../constants/assets";

const MobileScreen = () => {
  return (
    <div className={`d-flex flex-column ${styles.MainContainer}`}>
      <div className={`p-2 ${styles.topContainer}`}>
        <div></div>
        <h4 className={styles.headingStyles}>Welcome to </h4>
        <div className={styles.logoContainer}>
          <img
            src={IMAGES.LOGO_WHITENEW}
            alt="logo"
            className={styles.textLogo}
          />
        </div>
      </div>
      <div className={` ${styles.middleContainer}`}>
        <h5 className={styles.textData}>
          <span className={styles.boldText}>TalentLab</span> currently does not
          support mobile browsers.
        </h5>

        <h5 className={styles.textData}>
          Please use the latest version of Chrome, Safari, Firefox or Edge on
          desktop or laptop to access the
          <span className={styles.boldText}>{` TalentLab.`}</span>
        </h5>
      </div>
      <div className={`p-2 ${styles.bottomContainer}`}>
        <div></div>
        <div className={styles.BottomContent}>
          <p>Version 1.18.1</p>
          <p>Release Date: 22 Feb 2025</p>
          <p>© Copyright ExecHunt (India) Private Limited.</p>
        </div>
      </div>
    </div>
  );
};

export default MobileScreen;
