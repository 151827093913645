import React from "react";
import "./index.css";
import { IMAGES } from "constants/assets";
import LoginFooter from "components/loginFooter";

const TermsAndConditions = () => {
  return (
    <div
      className="tw-bg-[#0c2659] tw-flex tw-flex-col tw-justify-between tw-py-16 tw-bg-no-repeat tw-w-full tw-h-screen tw-bg-center tw-bg-cover"
      style={{
        backgroundImage: `url("/images/background.png")`,
      }}
    >
      <div className=" tw-flex tw-items-center tw-justify-center">
        <img width={200} src={IMAGES.LOGO_SVG} />
      </div>
      <div className="tw-w-8/12 tw-h-[70%] tw-p-16 tw-mx-auto tw-bg-white tw-shadow-md tw-rounded-md">
        <div className="tw-pr-4 tw-h-full tw-overflow-hidden tw-overflow-y-scroll no-scroll">
          <h2 className="tw-text-black !tw-m-0">
            <strong>Terms and Conditions</strong>
          </h2>
          <p className="tw-text-[#2e2e2e]">
            Updated 22<sup>nd</sup> Nov 2024
          </p>
          <p className="tw-text-[#2e2e2e]">Welcome to TalentLab!</p>
          <p className="tw-text-[#2e2e2e]">
            You must take the time to read and understand the Terms and
            Conditions before using our services. If you do not accept the Terms
            and Conditions stated below, please refrain from using our services.
          </p>
          <p className="tw-text-[#2e2e2e]">
            We use Your Personal data to provide and improve the Service. By
            using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy.
          </p>
          <h3 className="tw-text-black tw-text-lg tw-font-bold">
            Collecting and Using Your Personal Data
          </h3>
          <p className="tw-text-[#2e2e2e]">
            <strong>Types of Data Collected</strong>
          </p>
          <p className="tw-text-[#2e2e2e]">
            <strong>Personal Data of Talents</strong>
          </p>
          <p className="tw-text-[#2e2e2e]">
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
          </p>
          <ul className="tw-text-[#2e2e2e] tw-text-xs">
            <li>Name (required)</li>
            <li>Phone number</li>
            <li>Email (required)</li>
            <li>Photo (Avatar)</li>
            <li>Location details limited to country, state & city</li>
            <li>Social profile links</li>
            <li>Gender</li>
            <li>
              Employment history including designation served, name of
              organization and employment period
            </li>
            <li>
              Education history including name of degree, university name and
              degree period
            </li>
            <li>Certificates and publications details</li>
            <li>
              Documents such as resumes, offer letters, compensation documents,
              resignation acceptance letters
            </li>
            <li>
              Compensation history including fixed compensation, variable
              bonuses and ESOPs
            </li>
          </ul>
          <p className="tw-text-[#2e2e2e]">Personal Data of Organizations</p>
          <ul className="tw-text-[#2e2e2e] tw-text-xs">
            <li>Organization Name (required)</li>
            <li>Legal Name</li>
            <li>Organization Logo</li>
            <li>
              Point of contacts/ team members with their Name, Email, Mobile
              (required)
            </li>
            <li>Location details limited to country, state & city</li>
            <li>Social profile links</li>
            <li>
              Other documents such as contracts and invoices between TalentLab
              and the organization, organization’s GST certificate,
              organization’s PAN
            </li>
            <li>
              Billing information including name, email, designation, and mobile
              number for the billing representative; Organization’s GSTN and PAN
              number; Complete billing address.
            </li>
          </ul>
          <h3 className="tw-text-black tw-text-lg">
            <strong>Usage Data</strong>
          </h3>
          <p className="tw-text-[#2e2e2e]">
            Usage Data is collected automatically when using the Service.
          </p>
          <p className="tw-text-[#2e2e2e]">
            Further, the TalentLab guarantees that the data obtained by them is
            availed through ethical and legal means and protects its
            affiliates/vendors through this guarantee.
          </p>

          <h3 className="tw-text-black tw-text-lg tw-font-bold">
            Data Security
          </h3>
          <p className="tw-text-[#2e2e2e]">
            The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security.
          </p>

          <p className="tw-text-[#2e2e2e]">
            The measures taken to ensure the security and confidentiality of the
            collected data are all website traffic are routed through HTTPS
            layer with SSL, password encryption, role-based access controls,
            server level firewalls, all documents are signed with encrypted key
            and are only accessible with appropriate access, API rate limits,
            token-based authorization and regular security audits.
          </p>

          <h3 className="tw-text-black tw-text-lg tw-font-bold">
            Data Retention
          </h3>

          <p className="tw-text-[#2e2e2e]">
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.
          </p>

          <p className="tw-text-[#2e2e2e]">
            The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of Our Service, or We are legally
            obligated to retain this data for longer time periods.
          </p>

          <h3 className="tw-text-black tw-text-lg tw-font-bold">
            Transfer of Your Personal Data
          </h3>

          <p className="tw-text-[#2e2e2e]">
            Your information, including Personal Data, is processed at the
            Company’s operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers
            located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction.
          </p>

          <p className="tw-text-[#2e2e2e]">
            Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.
          </p>

          <p className="tw-text-[#2e2e2e]">
            The Company will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Privacy
            Policy and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of Your data and other personal
            information.
          </p>

          <h3 className="tw-text-black tw-text-lg tw-font-bold">
            Delete Your Personal Data
          </h3>

          <p className="tw-text-[#2e2e2e]">
            You have the right to delete or request that We assist in deleting
            the Personal Data that We have collected about You.
          </p>

          <p className="tw-text-[#2e2e2e]">
            Our Service may give You the ability to delete certain information
            about You from within the Service.
          </p>

          <p className="tw-text-[#2e2e2e]">
            You may update, amend, or delete Your information at any time by
            signing in to Your Account, if you have one, and visiting the
            account settings section that allows you to manage Your personal
            information. You may also contact Us to request access to, correct,
            or delete any personal information that You have provided to Us.
          </p>

          <p className="tw-text-[#2e2e2e]">
            Even after the information is deleted from the system, it may exist
            in our backups up to 30 days.
          </p>

          <h3 className="tw-text-black tw-text-lg tw-font-bold">
            Third-Party Sharing
          </h3>

          <p className="tw-text-[#2e2e2e]">
            We may share Your personal information in the following situations:
          </p>

          <p className="tw-text-[#2e2e2e]">
            <strong>With Service Providers:</strong> We may share Your personal
            information with Service Providers to monitor and analyze the use of
            our Service, to contact You.
          </p>
          <p className="tw-text-[#2e2e2e]">
            <strong>For business transfers:</strong> We may share or transfer
            Your personal information in connection with, or during negotiations
            of, any merger, sale of Company assets, financing, or acquisition of
            all or a portion of Our business to another company.
          </p>
          <p className="tw-text-[#2e2e2e]">
            <strong>With Affiliates:</strong> We may share Your information with
            Our affiliates, in which case we will require those affiliates to
            honor this Privacy Policy. Affiliates include Our parent company and
            any other subsidiaries, joint venture partners or other companies
            that We control or that are under common control with Us.
          </p>
          <p className="tw-text-[#2e2e2e]">
            <strong>With business partners:</strong> We may share Your
            information with Our business partners to offer You certain
            products, services or promotions.
          </p>
          <p className="tw-text-[#2e2e2e]">
            <strong>With other users:</strong> When You share personal
            information or otherwise interact in the public areas with other
            users, such information may be viewed by all users and may be
            publicly distributed outside.
          </p>
          <p className="tw-text-[#2e2e2e]">
            <strong>With Your consent:</strong> We may disclose Your personal
            information for any other purpose with Your consent.
          </p>

          <h3 className="tw-text-black tw-text-lg tw-font-bold">User Rights</h3>
          <p className="tw-text-[#2e2e2e]">
            Inform users of their rights regarding their personal data, such as:
          </p>
          <ul className="tw-text-[#2e2e2e] tw-text-xs">
            <li>right to be informed,</li>
            <li>right of access,</li>
            <li>right to rectification,</li>
            <li>right to erasure,</li>
            <li>right to restrict processing,</li>
            <li>right to data portability,</li>
            <li>right to object.</li>
          </ul>

          <h3 className="tw-text-black tw-text-lg tw-font-bold">
            Intellectual Property:
          </h3>

          <p className="tw-text-[#2e2e2e]">
            Intellectual property (IP) rights associated with the platform,
            including software, content, and databases, remains the property of
            TalentLab.
          </p>

          <h3 className="tw-text-black tw-text-lg tw-font-bold">
            Limitation of Liability
          </h3>

          <p className="tw-text-[#2e2e2e]">
            In no event shall TalentLab be liable for any claims, penalties,
            loss, damage or expenses, howsoever arising, out of or in connection
            with your use of the website, including, without limitation, direct
            or indirect loss, consequential loss or damage. Loss of profit or
            goodwill, loss of data, loss arising from use or inability to use
            the Website, loss arising from any errors or omissions in the
            website as a result of a breach of contract, negligence, delict.
            Nothing in these terms and conditions shall exercise or limit our
            liability for death or personal injury cause by negligence or for
            any liability which cannot be excluded or limited under applicable
            law.
          </p>

          <h3 className="tw-text-black tw-text-lg tw-font-bold">
            Indemnification
          </h3>

          <p className="tw-text-[#2e2e2e]">
            The users shall indemnify and hold harmless the company, its
            affiliates, vendors, and their respective officers, directors,
            employees, and agents from any legal claims, damages, or losses
            resulting from the user's actions or violations of the Terms and
            Conditions.
          </p>

          <h3 className="tw-text-black tw-text-lg tw-font-bold">
            Disclaimer of Warranties
          </h3>

          <p className="tw-text-[#2e2e2e]">
            You expressly agree that use of this site is at your sole risk. This
            site and all information and products described on this site are
            provided by Talentlab on an "as is" and "as available" basis, unless
            otherwise specified in writing. Neither TalentLab nor any of its
            affiliates, employees, agents, vendors, or licensors make any
            representations or warranties of any kind, express or implied as to
            the operation of this site or the information and products made
            available to you through this site, including implied warranties of
            Merchantability and fitness for a particular purpose.
          </p>

          <h3 className="tw-text-black tw-text-lg tw-font-bold">
            Termination and Account Suspension
          </h3>

          <p className="tw-text-[#2e2e2e]">
            TalentLab reserves the right to suspend or terminate user accounts
            in cases of violations of the Terms and Conditions or applicable
            laws or without any intimation or reason.
          </p>

          <h3 className="tw-text-black tw-text-lg tw-font-bold">
            Changes to Terms and Conditions
          </h3>

          <p className="tw-text-[#2e2e2e]">
            We may revise this Privacy Policy from time to time. The most
            current version of the policy will govern our use of your
            information and will always be at{" "}
            <a
              href="/privacy-policy"
              className="tw-text-blue-600 tw-underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy.
            </a>{" "}
            If we make a change to this policy that, in our sole discretion, is
            material, we will notify you via an email to the email address
            associated with your account. By continuing to access or use the
            Services after those changes become effective, you agree to be bound
            by the revised Privacy Policy.
          </p>

          <h3 className="tw-text-black tw-text-lg tw-font-bold">
            User Conduct and Prohibited Activities
          </h3>

          <p className="tw-text-[#2e2e2e]">
            You may not access or use, or attempt to access or use, the Services
            to take any action that could harm us or a third party. You may not
            use the Services in violation of applicable laws or in violation of
            our or any third party's intellectual property or other proprietary
            or legal rights. You further agree that you shall not attempt (or
            encourage or support anyone else's attempt) to circumvent, reverse
            engineer, decrypt, or otherwise alter or interfere with the
            Services, or any content thereof, or make any unauthorized use
            thereof.
          </p>

          <ul className="tw-text-[#2e2e2e] tw-text-xs">
            <li>
              When the user of the system encounters and reports the issue to{" "}
              <a
                className="tw-underline tw-text-blue-500"
                href="mailto:connect@talentlab.tech"
              >
                connect@talentlab.tech,
              </a>{" "}
              depending on the severity of the issue the resolution period will
              be within 2 to 14 business days.
            </li>
            <li>
              PeopleAsset/TalentLab will not offer any penalties to its users
              for any downtime originating from server issues or bugs or
              maintenance tasks.
            </li>
            <li>
              Company and/or its affiliates/vendors shall not be liable for any
              damages, losses, or downtime resulting from the unavailability or
              failure of the platform, technical support services, or
              third-party services integrated with the platform.
            </li>
          </ul>

          <h3 className="tw-text-black tw-text-lg tw-font-bold">
            Statistical Data Use
          </h3>

          <p className="tw-text-[#2e2e2e]">
            We process your information for purposes based on legitimate
            business interests, the fulfillment of our contract with you,
            compliance with our legal obligations, and/or your consent. We use
            personal information collected via our website for a variety of
            business purposes described below. We process your personal
            information for these purposes in reliance on our legitimate
            business interests, in order to enter into or perform a contract
            with you, with your consent, and/or for compliance with our legal
            obligations. We indicate the specific processing grounds we rely on
            next to each purpose listed below.
          </p>

          <p className="tw-text-[#2e2e2e]">
            We use the information we collect or receive:
          </p>

          <ul className="tw-text-[#2e2e2e] tw-text-xs">
            <li>
              <p className="tw-text-[#2e2e2e]">
                <strong>To send administrative information to you.</strong> We
                may use your personal information to send you product, service
                and new feature information and/or information about changes to
                our terms, conditions, and policies.
              </p>
            </li>

            <li>
              <p className="tw-text-[#2e2e2e]">
                <strong>To protect our Services.</strong> We may use your
                information as part of our efforts to keep our website safe and
                secure (for example, for fraud monitoring and prevention).
              </p>
            </li>
            <li>
              <p className="tw-text-[#2e2e2e]">
                <strong>
                  To enforce our terms, conditions and policies for business
                  purposes, to comply with legal and regulatory requirements or
                  in connection with our contract.
                </strong>
              </p>
            </li>
            <li>
              <p className="tw-text-[#2e2e2e]">
                <strong>To respond to legal requests and prevent harm.</strong>{" "}
                If we receive a subpoena or other legal request, we may need to
                inspect the data we hold to determine how to respond.
              </p>
            </li>
          </ul>

          <h3 className="tw-text-black tw-text-lg tw-font-bold">
            Protection against data leaks, thefts, malware attacks, hacking
            attempts, etc
          </h3>

          <p className="tw-text-[#2e2e2e]">
            We are committed to safeguarding and protecting personal information
            and will implement and maintain appropriate technical and
            organisational measures to ensure a level of security appropriate to
            protect any personal information provided to us from accidental or
            unlawful destruction, loss, alteration, unauthorised disclosure of,
            or access to personal information transmitted, stored or otherwise
            processed. we protect our own confidential information.
          </p>

          <p className="tw-text-[#2e2e2e]">
            However, the company or its affiliates and vendors will not be
            liable in any way for a data leak, data theft, malware attacks,
            hacking attempts, data breach etc. In a scenario where the above
            might happen, a notice will be issued to all TalentLab users
          </p>
        </div>
      </div>
      <div className="tw-flex tw-justify-center tw-items-center">
        <LoginFooter margin="0px"></LoginFooter>
      </div>
    </div>
  );
};

export default TermsAndConditions;
