import { Formik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IMAGES } from "../../constants/assets";
import { loginSchema } from "../../constants/formSchema";
import { handleLogin } from "../../redux/actions/auth";
import styles from "./styles.module.scss";
import EastIcon from "@mui/icons-material/East";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../router/routes";

// FORGET_PASSWORD

const defaultValues = {
  email: "",
  password: "",
};

const Login = () => {
  const navigate = useNavigate();
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);

  const onSubmit = (payload) => {
    dispatch(handleLogin(payload));
  };

  return (
    <div className={styles.container}>
      <div className={styles.rightContainer}>
        <div className={styles.info}>
          <span className={styles.infoTitle}>
            Welcome to{" "}
            <img
              src={IMAGES.LOGO_WHITENEW}
              alt="logo"
              className={styles.textLogo}
            />
          </span>
          <p className={styles.infoDesc}>
            The PeopleAsset TalentLab platform empowers its users to easily and
            efficiently take control of HR functions through a 3-tier modular
            setup targeted towards managing Talent, Organization (clients), and
            Executive Search assignments using one single cloud-based platform.
          </p>
        </div>
        <div className={styles.infoFooter}>
          <img src={IMAGES.LOGO_WHITE} alt="logo" />
          {/* <p className={styles.copyright}>Version 1.12</p>
      <p className={styles.copyright}>
        © Copyright ExecHunt (India) Private Limited
      </p> */}
        </div>
      </div>

      <div className={styles.leftContainer}>
        <div className={styles.login}>
          {/* <span className={styles.title}>Let's Get Started</span> */}
          <span className={`mb-3 ${styles.logoLogin}`}>
            <img
              src={IMAGES.LOGO_WHITEBLACK}
              className={styles.logoStyle}
              alt="logo"
            />
          </span>
          <p className={styles.logoGet}>
            Let's get started and sign you in{" "}
            <img src={IMAGES.LOGO_DOWNARROW} alt="logo" className="ms-0" />
          </p>
          <Formik
            onSubmit={onSubmit}
            initialValues={defaultValues}
            validationSchema={loginSchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className={styles.inputContainer}>
                  <div className={styles.labelContainer}>
                    <span className={styles.errorMsg}>
                      &nbsp;
                      {(errors.email && touched.email && errors.email) || ""}
                    </span>
                  </div>
                  <input
                    name="email"
                    type="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    placeholder="Enter your registered email address"
                    className={`${styles.input} ${
                      errors.email && touched.email ? styles.invalid : ""
                    }`}
                  />

                  <div className={styles.relative}>
                    <div className={styles.labelContainer}>
                      <span className={styles.errorMsg}>
                        &nbsp;
                        {(errors.password &&
                          touched.password &&
                          errors.password) ||
                          ""}
                      </span>
                    </div>
                    <input
                      name="password"
                      type={passwordVisibility ? "text" : "password"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      placeholder="Password"
                      className={`${styles.input} ${
                        errors.password && touched.password
                          ? styles.invalid
                          : ""
                      }`}
                    />{" "}
                    <img
                      alt="pasword"
                      className={styles.eyeViewHide}
                      src={passwordVisibility ? IMAGES.EYESLASH : IMAGES.EYE}
                      onClick={() => setPasswordVisibility(!passwordVisibility)}
                    />
                  </div>

                  <div className={styles.forgetContainer}>
                    <div
                      className={styles.forgotPass}
                      onClick={() => navigate(ROUTES.FORGET_PASSWORD)}
                    >
                      Forgot Password?
                    </div>
                  </div>

                  <button
                    type="submit"
                    className={styles.signInBtn}
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Sign In"}
                    <EastIcon className={styles.arrow} />
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className={styles.BottomContent}>
          <p>Version 1.18.1</p>
          <p>Release Date: 22 Feb 2025</p>
          <p>© Copyright ExecHunt (India) Private Limited.</p>
        </div>
      </div>
    </div>
  );
};

export default Login;
