import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import FormInput from "../../../../components/forms/formInput";
import { IMAGES } from "../../../../constants/assets";
import { talentEducationSchema } from "../../../../constants/formSchema";
import { listApi, addEditApi } from "../../../../api/commonApis";
import styles from "./styles.module.scss";
import Checkbox from "../../../../components/forms/checkbox";
import { monthOption } from "../../../../constants/contants";
import { APIS } from "../../../../api/endpoints";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../../router/routes";
import { saveAddNewButtonLabels } from "../../../../constants/contants";
import {
  validationError,
  yearDropDownOptions,
  EndYearDropDownOptions,
  order,
} from "../../../../utils/helper";
import { saveViewButtonLabels } from "../../../../constants/contants";
import { toast } from "react-toastify";
import LinkButton from "../../../../components/buttons/linkButton";
import ToastMessage from "../../../../components/layout/toast";
import cloneDeep from "lodash/cloneDeep";
import PrimaryButton from "../../../../components/buttons/primaryButton";
import OutLineButton from "../../../../components/buttons/outLineButton";
import { TOAST_MESSAGE } from "constants/toastMessage";

const initialValues = {
  degree: "",
  universityName: "",
  educationSynopsis: "",
  educationType: "",
  joinDateMonth: "",
  joinDateYear: "",
  currentlyStudy: false,
  endDateMonth: "",
  endDateYear: "",
};

const Education = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [educationData, setEducationData] = useState();
  const educationId = searchParams.get("educationId");
  const yearOption = yearDropDownOptions();
  const EducationEndYearOption = EndYearDropDownOptions();
  const [educationTypeOptions, setEducationTypeOptions] = useState();
  let action;

  useEffect(() => {
    let options = [];
    listApi(APIS.LIST_DATASET, {
      type: 6,
    }).then((result) => {
      const data = cloneDeep(result?.data?.data);
      options = data
        ?.map((value) => {
          return {
            label: value?.name,
            value: value?.name,
          };
        })
        .sort(order);

      setEducationTypeOptions(options);
    });
  }, []);

  useEffect(() => {
    if (educationId) {
      listApi(APIS.TALENT_DETAILS_TYPE_WISE, {
        talentId: id,
        type: 2,
        id: educationId,
      }).then((result) => {
        const data = cloneDeep(result?.data[0]);
        if (data.educationType === undefined) {
          data.educationType = "";
        } else {
          data.educationType = {
            label: data?.educationType,
            value: data?.educationType,
          };
        }
        setEducationData(data);
      });
    }
  }, [educationId]);
  const onSubmit = (payload, action) => {
    let data = {
      degree: payload?.degree,
      universityName: payload?.universityName,
      currentlyStudy: payload?.currentlyStudy,
      talentId: id,
    };
    if (payload?.educationType?.value) {
      data.educationType = payload?.educationType?.value;
    }

    if (payload?.joinDateMonth || payload?.joinDateMonth === "") {
      data.joinDateMonth = payload?.joinDateMonth;
    }
    if (payload?.joinDateYear || payload?.joinDateYear === "") {
      data.joinDateYear = payload?.joinDateYear;
    }
    if (payload?.educationSynopsis) {
      data.educationSynopsis = payload?.educationSynopsis;
    }
    if (educationId) {
      data.educationId = educationId;
    }
    if (payload.endDateMonth || payload?.endDateMonth === "") {
      data.endDateMonth = payload?.endDateMonth;
    }
    if (payload.endDateYear || payload?.endDateYear === "") {
      data.endDateYear = payload?.endDateYear;
    }

    addEditApi(APIS.ADD_EDIT_TALENT_EDUCATION, data).then((response) => {
      {
        if (action === "save") {
          navigate(`${ROUTES.TALENT}${ROUTES.OVERVIEW}/${id}?tab=education`);
        } else {
        }
        toast.success(
          <ToastMessage
            title={"Success"}
            message={TOAST_MESSAGE.SAVED_EDUCATION}
          />
        );
      }
    });
  };

  return (
    <div className={`tab-content ${styles.tabcontent}`}>
      <div className="fade show active">
        <Formik
          onSubmit={(value, { resetForm }) => {
            if (action === "add") {
              onSubmit(value, "add");
              resetForm();
            } else {
              onSubmit(value, "save");
            }
          }}
          enableReinitialize
          initialValues={educationData || initialValues}
          validationSchema={talentEducationSchema}
        >
          {({
            values,
            errors,
            touched,
            dirty,
            handleSubmit,
            getFieldProps,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className={styles.editprofile}>
                <div className={styles.edithead}>
                  <div className={styles.topheading}>
                    <h3>
                      {educationId
                        ? "Edit Education Experience History"
                        : "Add Education Experience History"}
                    </h3>
                    <div className="d-flex">
                      <LinkButton
                        onClick={() =>
                          navigate(
                            `${ROUTES.TALENT}${ROUTES.OVERVIEW}/${id}?tab=education`
                          )
                        }
                      >
                        Back to education
                      </LinkButton>
                      <PrimaryButton
                        onClick={() => validationError(errors)}
                        label={saveViewButtonLabels.educationSave}
                        disabled={!dirty}
                      />
                    </div>
                  </div>
                </div>

                <hr className={styles.cardDivider} />

                <div className={styles.formname}>
                  <div className={styles.middleCard}>
                    <FormInput
                      label="Title/Degree"
                      {...getFieldProps("degree")}
                      error={touched?.degree && errors?.degree}
                    />
                    <FormInput
                      label="University Name"
                      {...getFieldProps("universityName")}
                      error={touched?.universityName && errors?.universityName}
                    />

                    <FormInput
                      type="textarea"
                      id="educationSynopsis"
                      name="educationSynopsis"
                      label="Education Synopsis"
                      {...getFieldProps("educationSynopsis")}
                      error={
                        touched?.educationSynopsis && errors?.educationSynopsis
                      }
                    />

                    <FormInput
                      label="Education Type"
                      type="select"
                      placeholder="Select..."
                      // value={educationTypeOptions.find(
                      //   (obj) => obj?.value === values?.educationType
                      // )}
                      value={values?.educationType}
                      onChange={(e) => setFieldValue("educationType", e)}
                      options={educationTypeOptions}
                      error={touched?.educationType && errors?.educationType}
                    />

                    <div className={styles.dateContainer}>
                      <div className={styles.dateSection}>
                        <div className={styles.datePicker}>
                          <FormInput
                            isClearable
                            label="Joining Date"
                            type="select"
                            value={
                              values?.joinDateMonth
                                ? monthOption.find(
                                    (obj) => obj.value === values?.joinDateMonth
                                  )
                                : ""
                            }
                            options={monthOption}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("joinDateMonth", e.value);
                              } else {
                                setFieldValue("joinDateMonth", "");
                              }
                            }}
                            error={
                              touched?.joinDateMonth && errors?.joinDateMonth
                            }
                          />
                        </div>
                        <div className={styles.datePicker}>
                          <FormInput
                            isClearable
                            label="&nbsp;"
                            type="select"
                            value={
                              values?.joinDateYear
                                ? yearOption.find(
                                    (obj) => obj.value === values?.joinDateYear
                                  )
                                : ""
                            }
                            options={yearOption}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("joinDateYear", e.value);
                              } else {
                                setFieldValue("joinDateYear", "");
                              }
                            }}
                            error={
                              touched?.joinDateYear && errors?.joinDateYear
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className={styles.dateContainer}>
                      <div className={styles.dateSection}>
                        <div className={styles.datePicker}>
                          <FormInput
                            isClearable
                            isDisabled={
                              values?.currentlyStudy === true ? true : false
                            }
                            label="End Date"
                            type="select"
                            value={
                              values?.endDateMonth
                                ? monthOption.find(
                                    (obj) => obj.value === values?.endDateMonth
                                  )
                                : ""
                            }
                            options={monthOption}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("endDateMonth", e.value);
                              } else {
                                setFieldValue("endDateMonth", "");
                              }
                            }}
                            error={
                              touched?.endDateMonth && errors?.endDateMonth
                            }
                          />
                        </div>
                        <div className={styles.datePicker}>
                          <FormInput
                            isClearable
                            isDisabled={
                              values?.currentlyStudy === true ? true : false
                            }
                            label="&nbsp;"
                            type="select"
                            value={
                              values?.endDateYear
                                ? EducationEndYearOption.find(
                                    (obj) => obj.value === values?.endDateYear
                                  )
                                : ""
                            }
                            options={EducationEndYearOption}
                            onChange={(e) => {
                              if (e) {
                                setFieldValue("endDateYear", e.value);
                              } else {
                                setFieldValue("endDateYear", "");
                              }
                            }}
                            error={touched?.endDateYear && errors?.endDateYear}
                          />
                        </div>
                      </div>
                    </div>
                    <Checkbox
                      id="currentlyStudy"
                      label="Currently studying"
                      checked={values?.currentlyStudy}
                      {...getFieldProps("currentlyStudy")}
                    />
                  </div>
                  <hr className={styles.bottomCardDivider} />

                  <div className={styles.bottomFotter}>
                    {educationId ? (
                      <div></div>
                    ) : (
                      <OutLineButton
                        type="submit"
                        icon={IMAGES.IC_TWO}
                        onClick={() => {
                          action = "add";
                          onsubmit();
                        }}
                        label={saveAddNewButtonLabels.educationSave}
                      />
                    )}

                    <div className="d-flex">
                      <LinkButton
                        onClick={() =>
                          navigate(
                            `${ROUTES.TALENT}${ROUTES.OVERVIEW}/${id}?tab=education`
                          )
                        }
                      >
                        Back to education
                      </LinkButton>
                      <PrimaryButton
                        label={saveViewButtonLabels.educationSave}
                        onClick={() => {
                          action = "save";
                          onsubmit();
                        }}
                        disabled={!dirty}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Education;
