import { Provider } from "react-redux";
import { ToastContainer, Slide } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";
import { getState, persistor, store } from "./redux/store";
import Router from "./router";
import MobileScreen from "components/mobileView";
// import "../src/index.css";

import "./styles/index.scss";
import "react-toastify/dist/ReactToastify.css";
import styles from "./styles.module.scss";
import { useEffect } from "react";
import { isPast } from "date-fns";
import { BrowserRouter } from "react-router-dom";

const toastConfig = {
  autoClose: 4500,
  position: "bottom-right",
  transition: Slide,
  hideProgressBar: true,
};

const App = () => {
  useEffect(() => {
    setTimeout(() => {
      const user = getState()?.auth?.data;
      if (user) {
        const storage = JSON.parse(localStorage.getItem("persist:root"));

        const parsed_storage = storage ? JSON.parse(storage?.auth) : {};

        if (parsed_storage?.data !== undefined) {
          if (isPast(new Date(parsed_storage?.data?.expires))) {
            localStorage.removeItem("persist:root");
            localStorage.clear();
            sessionStorage.clear();
          }
        }
      }
    }, 3000);
  }, []);
  return (
    <>
      <div className={styles.mobileScreen}>
        <MobileScreen />
      </div>
      <div className={styles.browserscreen}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <BrowserRouter>
              <Router />
            </BrowserRouter>
            <ToastContainer {...toastConfig} />
          </PersistGate>
        </Provider>
      </div>
    </>
  );
};

export default App;
